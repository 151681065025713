// Library Import
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Checkbox } from "@mui/material";
import _ from "lodash";
import { useSelector } from "react-redux";

// Files Import
import ButtonFilled from "../button/ButtonFilled";
import { AttachmentStatus } from "../../services/constants/constants";
import CustomizedTooltip from "../atoms/Tooltip/CustomizedTooltip";

// Icons Import
import DownCaretLogo from "../../assets/Extraction/Tables/downCaret.svg";
import ExtractionLogo from "../../assets/Extraction/extractionLogo.svg";

// Styles Import
import "./DocumentSwitcher.css";

function DocumentSwitcher(props) {
  const {
    submissionId,
    documentName,
    isExtractionConfirmed = false,
    currentDocumentId = "",
    isExtractionIcon = true,
  } = props;
  const submission = useSelector((state) => state.submissions.submission);
  const timer = useSelector((state) => state.timer);
  const selectedComparisonDocIds = useSelector(
    (state) => state.comparison.selectedDocumentIds
  );

  const [navigationDropdown, setNavigationDropdown] = useState([]);
  const [navigationDropdownValue, setNavigationDropdownValue] =
    useState(documentName);
  const [openNavDropdown, setOpenNavDropdown] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState([]);

  const navigate = useNavigate();

  const fetchDocumentName = () => {
    const temp = [];
    if (selectedComparisonDocIds.length < 2) {
      setNavigationDropdownValue("Select Document");
    } else {
      setNavigationDropdownValue(documentName);
    }
    if (submission?.attachments) {
      submission?.attachments.forEach((value) => {
        if (value.status === AttachmentStatus.Processed) {
          // const index =
          const obj = {
            key: value.document_id,
            value: `${value.document_name}`,
            classification:
              (value?.transaction_description || value?.classification) ?? "",
            isChecked: selectedComparisonDocIds.find(
              (x) => x.document_id === value.document_id
            )
              ? true
              : false,
            // isChecked: false,
          };
          temp.push(obj);
        }
      });
      setNavigationDropdown(temp);
    }
  };

  const openNavigatioDropdown = () => {
    setOpenNavDropdown((prevState) => !prevState);
  };

  const handleCheckbox = (values) => {
    const navigationCp = _.cloneDeep(navigationDropdown);
    if (
      navigationCp.find((x) => x.key.toLowerCase() === values.key.toLowerCase())
        .isChecked
    ) {
      navigationCp.find(
        (x) => x.key.toLowerCase() === values.key.toLowerCase()
      ).isChecked = false;
      const selectedDocumentsCp = _.cloneDeep(selectedDocuments);
      const spliceIndex = selectedDocumentsCp.findIndex(
        (x) => x.key.toLowerCase() === values.key.toLowerCase()
      );
      selectedDocumentsCp.splice(spliceIndex, 1);
      setSelectedDocuments(selectedDocumentsCp);
    } else {
      navigationCp.find(
        (x) => x.key.toLowerCase() === values.key.toLowerCase()
      ).isChecked = true;
      const selectedDocumentsCp = _.cloneDeep(selectedDocuments);
      selectedDocumentsCp.push(values);
      setSelectedDocuments(selectedDocumentsCp);
    }
    setNavigationDropdown(navigationCp);
  };

  const handleCheckboxOnLoad = () => {
    const selectedDocumentsCp = [];
    selectedComparisonDocIds.forEach((doc) => {
      const obj = {
        key: doc.document_id,
        value: doc.document_name,
        classification:
          (doc?.classification) ?? "",
        isChecked: true,
        // isChecked: false,
      };
      selectedDocumentsCp.push(obj);
    });
    setSelectedDocuments(selectedDocumentsCp);
  };

  const gotoExtraction = (values) => {
    setNavigationDropdownValue(values.value);
    const route = `/home/submissions/${submissionId}/extraction?docs=${values.key}`;
    navigate(route, { state: { startTimer: !timer?.isTimerPaused } });
  };

  const viewComparison = () => {
    setNavigationDropdownValue(
      selectedDocuments.map((x) => x.classification).join(",")
    );
    navigate(
      `/home/submissions/${submissionId}/comparison?docs=${selectedDocuments
        .map((x) => x.key)
        .join(",")}`
    );
    setOpenNavDropdown(false);
  };

  const goToEnrichment = () => {
    navigate(
      `/home/submissions/${submissionId}/enrichment?docs=${currentDocumentId}`
    );
  };

  const goToValidation = () => {
    navigate(`/home/submissions/${submissionId}/validation`);
  };

  useEffect(() => {
    fetchDocumentName();
    if (selectedComparisonDocIds.length) {
      setNavigationDropdownValue(
        selectedComparisonDocIds.map((x) => x.classification).join(",")
      );
      handleCheckboxOnLoad();
    }
  }, [submission?.attachments]);

  return (
    <>
      <span
        className="h3Regular"
        style={{ color: "#616E84", marginRight: "0.25rem" }}
      >
        viewing:
      </span>
      <div className="viewing-dropdown">
        <div
          className="viewing-dropdown-values"
          onClick={openNavigatioDropdown}
        >
          <div className="viewing-dropdown-text">{navigationDropdownValue}</div>
          <div className="down-caret-logo">
            <img src={DownCaretLogo} alt="Down-caret-logo" />
          </div>
        </div>
        {openNavDropdown && (
          <div className="viewing-dropdown-menu">
            <div className="viewing-dropdown-menu-options">
              {navigationDropdown.map((values, valuesIndex) => (
                <div className="values-container">
                  <div
                    className="values"
                    onClick={() => {
                      handleCheckbox(values);
                    }}
                  >
                    {/* <FormControlLabel control={} label={values.value} /> */}
                    <Checkbox
                      // onChange={() => {
                      //   handleCheckbox(values);
                      // }}
                      checked={values.isChecked}
                    />
                    <CustomizedTooltip text={values.value} position="top">
                      <span
                        style={{
                          width: "200px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          textAlign: "left",
                        }}
                      >
                        {values.classification} | {values.value}
                      </span>
                    </CustomizedTooltip>
                  </div>
                  {isExtractionIcon && (
                    <div
                      className="extraction-logo"
                      onClick={() => {
                        gotoExtraction(values);
                      }}
                    >
                      <img src={ExtractionLogo} alt="extraction-logo" />
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="viewing-dropdown-menu-view-comp-button">
              <ButtonFilled
                text="View Comparison"
                width="9.25rem"
                height="2rem"
                onClick={viewComparison}
                disabled={
                  selectedDocuments.length < 2 || selectedDocuments.length > 3
                }
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default DocumentSwitcher;
