import React from 'react';
import './SubmissionAttachment.css';
import { axiosCancelToken } from '../../../../config/axios';
import {
  FormattedAttachmentStatus, FormattedAttachmentConfidence, SubmissionAttachmentPreview,
  SubmissionAttachmentCarrier, SubmissionAttachmentClassification, SubmissionAttachmentSubClassification, SubmissionAttachmentActions
} from '../index';
import {
  ClassificationTypes, SubClassificationTypes, AccordFormType, AttachmentStatus,
  PipelineRetryCount, CarrierTypes, PolicyDocumentType, ReadOnlyMode, MergedType, CanShowComparison, MergeDocument
}
  from '../../../../services/constants/constants';
import { ProgressSpinner } from 'primereact/progressspinner';
import { InnerTableCell } from '../../../table-style/StyledTableCell';
import { Checkbox, TableRow } from '@mui/material';
import CustomizedTooltip from '../../../atoms/Tooltip/CustomizedTooltip';
import useAxios from '../../../../hooks/useAxios';

const SubmissionAttachment = React.forwardRef((props, ref) => {

  const axiosPrivate = useAxios();
  const attachmentActionsCompRef = React.useRef()
  const { submissionId, attachment, attachments, setXmlPreview, isUploaded, handleDocumentClassification, handleMergedClassificationDoneProcess,
    fetchSubmissions, isOldSubmission, selectedDocuments, setSelectedDocuments } = props;
  const [loaded, setLoaded] = React.useState(false);
  const [attachmentDetails, setAttachmentDetails] = React.useState(attachment);
  const [attachmentDetailsOrg, setAttachmentDetailsOrg] = React.useState(attachment);
  const [subClassificationTypes, setSubClassificationTypes] = React.useState([]);
  const [editMode, setEditMode] = React.useState(false);
  const [old_output_json, set_old_output_json] = React.useState(null)
  const [selected, setSelected] = React.useState(false);
  const isMounted = React.useRef(false);

  React.useImperativeHandle(ref, () => ({
    approveDocument() {
      attachmentActionsCompRef.current.approveDocument();
    },
  }));

  const hasSubClassification = (type) => {
    return SubClassificationTypes.some(
      (x) => x.key.toUpperCase() === type?.toUpperCase()
    );
  }

  const setPresignedUrl = (presignedUrl) => {
    const details = { ...attachmentDetails, document_url: presignedUrl }
    setAttachmentDetails(details);
  }


  const handleClassificationEdit = () => {
    if (old_output_json === null) {
      set_old_output_json(JSON.parse(JSON.stringify(attachmentDetailsOrg.output_json)));
    }

    const classification = attachmentDetails?.output_json?.type;
    const subClassification = attachmentDetails?.output_json?.sub_type;

    let subTypes = SubClassificationTypes.filter(
      (x) =>
        x.key.toUpperCase() === classification.toUpperCase()
    );

    if (classification.toUpperCase() === AccordFormType.toUpperCase()) {
      const subType = subClassification ?? 'Other';

      const keyValue = { key: classification, value: subType };
      subTypes.push(keyValue);
    }

    setSubClassificationTypes(subTypes);
    setEditMode(true);
  }

  const handleClassificationEditCancel = () => {
    setSubClassificationTypes([]);
    setEditMode(false);
    const attachment = { ...attachmentDetails };
    attachment.output_json = JSON.parse(JSON.stringify(attachmentDetailsOrg.output_json));
    setAttachmentDetails(attachment);
  }

  const handleClassificationUpdate = () => {
    setSubClassificationTypes([]);
    setEditMode(false);
    const attachment = { ...attachmentDetailsOrg };
    attachment.output_json = JSON.parse(JSON.stringify(attachmentDetails.output_json));
    attachment.output_json.hitl_confirmed = true;
    set_old_output_json(JSON.parse(JSON.stringify(attachmentDetails.output_json)))
    setAttachmentDetails(attachment);
    setAttachmentDetailsOrg(JSON.parse(JSON.stringify(attachment)));

    handleDocumentClassification(attachment);
  }

  const handleClassificationTypeChange = (classification) => {
    let subClassificationTypes;
    let subtype;

    if (hasSubClassification(classification)) {
      subClassificationTypes = SubClassificationTypes.filter(
        (x) => x.key.toUpperCase() === classification.toUpperCase()
      );
      subtype = subClassificationTypes[0].value;
    } else {
      subClassificationTypes = [];
      subtype = '';
    }

    const details = { ...attachmentDetails };
    details.output_json.type = classification;
    // details.output_json.sub_type = subtype;
    details.output_json.confidence = 1;

    setSubClassificationTypes(subClassificationTypes);
    setAttachmentDetails(details);
  }

  const handleClassificationSubTypeChange = (classification) => {
    const details = { ...attachmentDetails };
    details.output_json.sub_type = classification;
    details.output_json.sub_confidence = 0;

    setAttachmentDetails(details);
  }

  const handleCarrierTypeChange = (carrier) => {
    const details = { ...attachmentDetails };
    details.output_json.carrier_name = carrier;
    details.carrier_name = carrier;

    setAttachmentDetails(details);
  };

  const handleExtractionInProgress = () => {
    const details = { ...attachmentDetails };
    details.status = AttachmentStatus.Extracting;

    setAttachmentDetails(details);
    handleSilentRefresh(details.status);
    handleDocumentClassification(details);
  }

  const handleXmlPreview = (data) => {
    setXmlPreview(data);
  }

  const handleClassificationApproval = () => {
    const details = { ...attachmentDetails };
    details.output_json.approved = true;

    setAttachmentDetails(details);
    handleDocumentClassification(details);
  }

  const loadDocumentStatus = (submissionId, documentId) => {

    axiosPrivate
      .get(`submissions/${submissionId}/documents/${documentId}/classification`)
      .then(response => {
        if (response.data) {
          const doc = { ...attachmentDetails }
          doc.status = response.data.status;
          doc.output_json = response.data.output_json;
          doc.retry_count += 1;

          if (doc.status !== AttachmentStatus.Processed &&
            doc.status !== AttachmentStatus.Classified) {
            if (doc.retry_count <= PipelineRetryCount || isNaN(doc.retry_count)) {
              setTimeout(() => {
                if (!isMounted.current) {
                  return;
                }

                handleSilentRefresh(doc.status);
              }, 6000);
            }
          } else {
            if (doc.output_json.type.toUpperCase() === PolicyDocumentType.toUpperCase() &&
              !doc.output_json?.sub_type?.length &&
              doc.status === AttachmentStatus.Processed) {
              setTimeout(() => {
                if (!isMounted.current) {
                  return;
                }

                loadDocumentStatus(submissionId, documentId);
              }, 2000);
            } else {
              doc.retry_count = 0;
            }

            if (doc.output_json.type.toUpperCase() === MergedType.toUpperCase()) {
              handleMergedClassificationDoneProcess(submissionId);
            }
          }

          setAttachmentDetails(doc);
          setAttachmentDetailsOrg(JSON.parse(JSON.stringify(doc)));
          handleDocumentClassification(doc);
        }
      }, err => {
        console.log(err)
      })
  }

  const fetchAttachment = async (config) => {

    const response = await axiosPrivate
      .get(`submissions/${submissionId}/documents/${attachmentDetails.document_id}/classification`, config)
      .catch();

    const doc = response.data;
    doc.retry_count = 0;

    setAttachmentDetails(doc);
    setAttachmentDetailsOrg(JSON.parse(JSON.stringify(doc)));

    handleDocumentClassification(doc);
    setLoaded(true);

    setTimeout(() => {
      if (!isMounted.current) {
        return;
      }

      handleSilentRefresh(doc.status);
    }, 6000);
  }

  const handleSilentRefresh = (status) => {
    if (status === AttachmentStatus.Extracting || status === AttachmentStatus.Classifying ||
      status === AttachmentStatus.Received) {
      loadDocumentStatus(submissionId, attachment.document_id);
    }
  };

  const getActionTooltip = (attachment) => {
    return (
      <React.Fragment>
        <span>Excel Downloaded:
          <span>{attachment.download_excel_flag === true ? ' Yes ' : ' No '}</span>
        </span>
        <br />
        <span>Json Downloaded:
          <span>{attachment.download_json_flag === true ? ' Yes ' : ' No '}</span>
        </span>
      </React.Fragment>
    );
  }

  const handleDocumentSelect = (e) => {
    if (e.target.checked) {
      setSelected(true);

      const updatedSelectedDocuments = JSON.parse(JSON.stringify(selectedDocuments));

      const index = updatedSelectedDocuments.findIndex(x => x.submission_id === submissionId);

      if (index === -1) {
        updatedSelectedDocuments.push({
          submission_id: submissionId,
          selectedCount: 1,
          documents: [e.target.name]
        })
      } else {
        updatedSelectedDocuments[index].selectedCount += 1;
        updatedSelectedDocuments[index].documents.push(e.target.name);
      }
      setSelectedDocuments(updatedSelectedDocuments);
    } else {
      setSelected(false);
      const updatedSelectedDocuments = JSON.parse(JSON.stringify(selectedDocuments));

      const index = updatedSelectedDocuments.findIndex(x => x.submission_id === submissionId);
      updatedSelectedDocuments[index].selectedCount -= 1

      const documentIndex = updatedSelectedDocuments[index].documents.findIndex(x => x === e.target.name);

      if (documentIndex > -1) {
        updatedSelectedDocuments[index].documents.splice(documentIndex, 1);
      }
      setSelectedDocuments(updatedSelectedDocuments);
    }
  };

  const canSelect = () => {
    const temp = selectedDocuments.find(x => x.submission_id === submissionId);
    if (temp && temp.selectedCount === 3) {
      return false;
    } else {
      return true;
    }
  };

  React.useEffect(() => {
      isMounted.current = true;
      const source = axiosCancelToken.source();
      const config = { cancelToken: source.token };
      fetchAttachment(config);

    return () => {
      isMounted.current = false;
      source.cancel();
    }
  }, []);

  const documentSeparation = () => {
    const documentIndex = attachments.findIndex(x => x.document_id === attachmentDetails.document_id);
    if(documentIndex < attachments.length-1 && attachments[documentIndex]?.parent_document_id !== null && attachments[documentIndex]?.parent_document_id !== '' && attachments[documentIndex + 1]?.parent_document_id === null){
      return true;
    }

    if (attachmentDetails?.output_json?.type !== MergeDocument.key && !attachmentDetails?.is_splitted_document) {
      return true;
    }
    return false;
  }

  return (
    <React.Fragment>
      <TableRow className={documentSeparation() && 'thick-border'}>
        <InnerTableCell style={{ width: '2rem' }} className='text-center checkbox-td'>
          {attachmentDetails && attachmentDetails.status === AttachmentStatus.Processed && loaded && CanShowComparison && (
            !canSelect() && !selected ? (
              <CustomizedTooltip
                text="Maximum limit of 3 documents reached to view comparison"
                position="top"
              >
                <div>
                  <Checkbox
                    name={attachmentDetails.document_id}
                    size="small"
                    checked={selected}
                    onChange={handleDocumentSelect}
                    disabled={!canSelect()}
                  />
                </div>
              </CustomizedTooltip>
            ) : (
              <Checkbox
                name={attachmentDetails.document_id}
                size="small"
                checked={selected}
                onChange={handleDocumentSelect}
                disabled={!canSelect() && !selected}
              />
            )
          )}
        </InnerTableCell>
        <InnerTableCell style={{ width: '3rem' }} className='text-center'>
          {!loaded ?
            <div className='progress-loader'>
              <ProgressSpinner style={{ width: '20px', height: '20px' }} strokeWidth='8' fill='var(--surface-ground)' animationDuration='.5s' />
            </div>
            :
            <SubmissionAttachmentPreview
              submissionId={submissionId}
              documentId={attachmentDetails.document_id}
              documentName={attachmentDetails?.document_name}
              documentUrl={attachmentDetails?.document_url}
              setPresignedUrl={setPresignedUrl} />
          }
        </InnerTableCell>
        <InnerTableCell style={{ width: '16.75rem' }}>
          <span className='truncate label-truncate attach-ptooltip'
            style={{ fontSize: '12px', fontWeight: 500, marginLeft: attachmentDetails.is_splitted_document && '1rem' }}>
            <CustomizedTooltip text={attachmentDetails.document_name}>
              <span>
                {attachmentDetails.document_name}
              </span>
            </CustomizedTooltip>
          </span>
        </InnerTableCell>
        {/* <InnerTableCell>
          <span className='truncate label-truncate attach-ptooltip'>
            <SubmissionAttachmentCarrier
              carrier={attachmentDetails?.output_json?.carrier_name}
              carrierTypes={CarrierTypes}
              editMode={editMode}
              status={attachmentDetails?.status}
              handleCarrierTypeChange={handleCarrierTypeChange}
            />
          </span>
        </InnerTableCell> */}
        <InnerTableCell>
          <span className='truncate label-truncate attach-ptooltip'>
            {loaded &&
              <SubmissionAttachmentClassification documentName={attachmentDetails?.document_name}
                classification={attachmentDetails?.output_json?.type === MergeDocument.key ? MergeDocument.value : attachmentDetails?.output_json?.type}
                classificationTypes={ClassificationTypes}
                status={attachmentDetails?.status}
                editMode={editMode}
                handleClassificationTypeChange={handleClassificationTypeChange} />
            }
          </span>
        </InnerTableCell>
        {/* <InnerTableCell>
          <span className='truncate label-truncate attach-ptooltip'>
            {loaded &&
              <SubmissionAttachmentSubClassification documentName={attachmentDetails?.document_name}
                classification={attachmentDetails?.output_json?.type}
                subClassification={attachmentDetails?.output_json?.sub_type}
                subClassificationTypes={subClassificationTypes}
                status={attachmentDetails?.status}
                editMode={editMode}
                handleClassificationSubTypeChange={handleClassificationSubTypeChange} />
            }
          </span>
        </InnerTableCell> */}
        <InnerTableCell>
          {loaded &&
            <FormattedAttachmentConfidence documentName={attachmentDetails.document_name}
              classification={attachmentDetails?.output_json?.type}
              confirmed={attachmentDetails?.output_json?.hitl_confirmed}
              score={attachmentDetails?.output_json?.confidence} />
          }
        </InnerTableCell>
        <InnerTableCell>
          {<FormattedAttachmentStatus status={attachmentDetails.status} />}
        </InnerTableCell>
        <InnerTableCell>
          {loaded &&
            <SubmissionAttachmentActions ref={attachmentActionsCompRef}
              submissionId={submissionId}
              documentId={attachmentDetails.document_id}
              parentDocumentId = {attachmentDetails.parent_document_id}
              attachment={attachmentDetails}
              isUploaded={isUploaded}
              editMode={editMode}
              isOldSubmission={isOldSubmission}
              handleXmlPreview={handleXmlPreview}
              handleClassificationEdit={handleClassificationEdit}
              handleClassificationEditCancel={handleClassificationEditCancel}
              handleClassificationUpdate={handleClassificationUpdate}
              handleExtractionInProgress={handleExtractionInProgress}
              handleClassificationApproval={handleClassificationApproval}
              fetchSubmissions={fetchSubmissions}
              old_output_json={old_output_json}
              documents={attachments}
            />
          }
        </InnerTableCell>

        {
          ReadOnlyMode === true &&
          <InnerTableCell>
            <CustomizedTooltip text={getActionTooltip(attachment)} position="right">
              <img src={"assets/images/info.svg"} alt="info" height="14" style={{ paddingLeft: '0.5rem', cursor: 'pointer' }} />
            </CustomizedTooltip>
          </InnerTableCell>
        }
      </TableRow>
    </React.Fragment>
  )
})

export default React.memo(SubmissionAttachment);
