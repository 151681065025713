import { IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CloseOutlined } from '@ant-design/icons';

function ControlledTooltip(props) {
  const { text, open, position, sideDrawerTooltip = false, handleCloseTooltip } = props;
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setOpenLocal(prevState => !prevState);
  //   }, 5000);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);
  return (
    <Tooltip
      title={
      sideDrawerTooltip ? 
      <div style={{display: open ? 'flex' : 'none', flexDirection: 'row'}}>
      <div className="h3Regular" style={{ color: '#FFFFFF', flexWrap: 'wrap', width: '15rem' }}>{text}</div>
      <div onClick={handleCloseTooltip}>
        <CloseOutlined style={{ color: 'white', fontSize: '12px' }} />
      </div>
      </div>
      :
      <span className="h3Regular" style={{ color: '#FFFFFF' }}>{text}</span>
      }
      placement={position ? position : "right"}
      arrow
      disableInteractive
      disableHoverListener
      open={true}
      enterDelay={500}
      leaveDelay={500}
      componentsProps={{
        tooltip: {
          sx: {
            pointerEvents: 'auto',
            maxWidth: '30rem',
            bgcolor: '#395DAB',
            margin: 0,
            visibility: open ? 'visible' : 'hidden',
            '& .MuiTooltip-arrow': {
              color: '#395DAB'
            }
          }
        },
      
        popper: {
          sx: {
            zIndex: sideDrawerTooltip ?  100000 + '!important' : 1000 + '!important',
            marginLeft: sideDrawerTooltip ? '-0.5rem !important' : 0,
          }
        }
      }}
    >
      {props.children}
    </Tooltip>
  );
}

export default ControlledTooltip;
