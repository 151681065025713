import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  buttonStyle: {
    color: '#2D4081',
    borderRadius: '0.25rem',
    textTransform: 'none',
    height: '2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  defaultStyle: {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: '#E8F0FB',
    },
    '&:active': {
      backgroundColor: '#C2DBFF',
    },
  },
  filledStyle: {
    backgroundColor: '#2D4081',
    '&:hover': {
      backgroundColor: '#395DAB',
    },
    '&:active': {
      backgroundColor: '#2D4081',
    },
  },
  logoText: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.25rem',
  },
  logo: {
    // marginRight: '0.5rem',
  },
}));
function ButtonOutlined(props) {
  const {
    text,
    onClick,
    icon,
    borderColor = '#AEB3BC',
    textColor = '#3C4C68',
    disabled,
    width = '',
    height = '',
    marginRight = '',
    imageMargin = '',
    isImage = true,
    isFilled = false,
  } = props;
  const classes = useStyles();

  return (
    <div>
      <button
        type="button"
        style={{
          border: `1px solid ${borderColor}`,
          width: width,
          height: height,
          marginRight: marginRight,
          cursor: disabled ? 'not-allowed' : 'pointer'
        }}
        className={`h3Medium ${classes.buttonStyle} ${isFilled ? classes.filledStyle : classes.defaultStyle}`}
        onClick={onClick}
        disabled={disabled}
      >
        <span className={`${classes.logoText}`}>
          {isImage ? (
            icon && <img className={`${classes.logo}`} src={icon} alt="logo" style={{ marginInline: imageMargin }} />
          ) : (
            icon
          )}
          <span style={{ color: textColor, fontWeight: '500', }}>{text}</span>
        </span>
      </button>
    </div>
  );
}
export default ButtonOutlined;
