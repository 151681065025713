import file from '../../assets/sidebar/file.svg';
import fileActive from '../../assets/sidebar/fileActive.svg';
import docManageIcon from '../../assets/sidebar/docManageIcon.svg';
import docManageActive from '../../assets/sidebar/docManageActive.svg';
import opsDashboardIcon from '../../assets/sidebar/opsDashboardIcon.svg';
import opsDashboardActive from '../../assets/sidebar/opsDashboardActive.svg';
import { ReadOnlyMode } from '../constants/constants';

function NavbarRoutes() {

    let navBarItems = [
        {
            label: 'File Dashboard',
            tooltip: 'File Dashboard',
            icon_container: 'icons',
            icon: file,
            activeIcon: fileActive,
            href: '/home/file-submissions'
        },
        // {
        //     label: 'Document Management',
        //     tooltip: 'Document Management',
        //     icon_container: 'icons',
        //     icon: docManageIcon,
        //     activeIcon: docManageActive,
        //     href: '/home/document-management'
        // },
        {
            label: 'Insights Dashboard',
            tooltip: 'Insights Dashboard',
            icon_container: 'icons',
            icon: opsDashboardIcon,
            activeIcon: opsDashboardActive,
            href: '/home/insights-dashboard'
        },
    ];

    if (ReadOnlyMode === true) {
       navBarItems = navBarItems.filter(object => {
            return object.label?.toLowerCase() !== "file upload";
        });
    }

    return navBarItems;
}

export default NavbarRoutes
