import React from "react";
import "./FormattedAttachmentStatus.css";
import { AttachmentStatus } from "../../../../services/constants/constants";
import { titlecase, removeUnderscore } from "../../../../services/utils/Helper";
import { CircularProgress } from "@mui/material";

const FormattedAttachmentStatus = ({ status }) => {
  const getAttachmentStatusBg = (status) => {
    let bg = "bg-secondary";
    switch (status) {
      case AttachmentStatus.Received:
        bg = "#FFAB00";
        break;
      case AttachmentStatus.Classifying:
        bg = "bg-danger";
        break;
      case AttachmentStatus.Classified:
        bg = "#D6792D";
        break;
      case AttachmentStatus.Extracting:
        bg = "white";
        break;
      case AttachmentStatus.Processed:
        bg = "#24B082";
        break;
      case AttachmentStatus.Reviewed:
        bg = "#5892F9";
        break;
      default:
        bg = "white";
    }

    return bg;
  };

  const getAttachmentStatusFormattedValue = (status) => {
    return removeUnderscore(titlecase(status));
  };

  return (
    <div className={`status-container`}>
      {getAttachmentStatusFormattedValue(status).toLowerCase() !==
        AttachmentStatus.Classifying &&
        getAttachmentStatusFormattedValue(status).toLowerCase() !==
        AttachmentStatus.Extracting ? (
        <div
          className="dots"
          style={{
            backgroundColor: `${getAttachmentStatusBg(status)}`,
            border: `1px solid ${getAttachmentStatusBg(status)}`,
          }}
        ></div>
      ) : (
        <div className="loading">
          <CircularProgress color="inherit" size="0.8rem" />
        </div>
      )}
      <span className={getAttachmentStatusFormattedValue(status).toLowerCase() !==
        AttachmentStatus.Classifying &&
        getAttachmentStatusFormattedValue(status).toLowerCase() !==
        AttachmentStatus.Extracting ? 'h3Medium' : 'h3Regular'}>{getAttachmentStatusFormattedValue(status)}</span>
    </div>
  );
};

export default React.memo(FormattedAttachmentStatus);
