import React, {
    createContext, useContext, useEffect, useState, useMemo,
  } from 'react';
  import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signInWithPopup,
    onAuthStateChanged,
    signOut,
  } from 'firebase/auth';
  import { authInstance } from '../services/utils/firebase';
  
  const userAuthContext = createContext();
  
  export function UserAuthContextProvider({ children }) {
    const [user, setUser] = useState(null);
    const [loaded, setLoaded] = useState(false);
  
    function logInWithEmailAndPassword(email, password) {
      return signInWithEmailAndPassword(authInstance, email, password);
    }
  
    function logInWithProvider(provider) {
      return signInWithPopup(authInstance, provider);
    }
  
    function signUp(email, password) {
      return createUserWithEmailAndPassword(authInstance, email, password);
    }
  
    function logOut() {
      return signOut(authInstance);
    }
  
    useEffect(() => {
      const unsubscribe = onAuthStateChanged(authInstance, (currentuser) => {
        setUser(currentuser);
        setLoaded(true);
      });
  
      return () => {
        unsubscribe();
      };
    }, []);
  
    const authValue = useMemo(() => ({
      user, logInWithEmailAndPassword, logInWithProvider, signUp, logOut, loaded
    }), [user, loaded]);
  
    return (
      <userAuthContext.Provider value={authValue}>
        {children}
      </userAuthContext.Provider>
    );
  }
  
  export function useUserAuth() {
    return useContext(userAuthContext);
  }
  