import { TimerActionTypes } from "../constants/timerActionTypes"

export const setReviewTime = (time) => {
    return {
        type: TimerActionTypes.SET_REVIEW_TIME,
        payload: time
    }
}

export const setIsTimerPaused = (flag) => {
    return {
        type: TimerActionTypes.SET_IS_TIMER_PAUSED,
        payload: flag
    }
}

export const setIsResetPopupOpen = (flag) => {
    return {
        type: TimerActionTypes.SET_IS_RESET_POPUP_OPEN,
        payload: flag
    }
}

export const setIsTimeZero = (flag) => {
    return {
        type: TimerActionTypes.SET_IS_TIME_ZERO,
        payload: flag
    }
}

