import React from 'react';
import './FormattedAttachmentConfidence.css';
import { roundToTwo } from '../../../../services/utils/Helper';

const FormattedAttachmentConfidence = ({ documentName, classification, confirmed, score }) => {

    const isLimitedExtension = () => {
        return documentName.toUpperCase().endsWith('XLSX');
    }

    const isMergedClassification = () => {
        return classification?.toUpperCase() === 'MERGED';
    }

    const isClassificationUnknown = () => {
        return classification?.toUpperCase() === 'UNKNOWN';
    }

    const getConfidenceScore = () => {
        if (score === 0) {
            return '';
        }

        const value = +score * 100;
        return `${roundToTwo(value)}%`;
    }

    return (
        <React.Fragment>
            {confirmed ?
                <React.Fragment>
                    <span className='truncate label-truncate attach-ptooltip'>
                        <img className='status-img' src='/assets/images/green-tick.png' alt="green-tick" />
                    </span>
                </React.Fragment>
                :
                <React.Fragment>
                    {!isMergedClassification() && !isClassificationUnknown()
                        &&
                        <span className='cnf-ptooltip'>
                            {getConfidenceScore()}
                        </span>
                    }
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default React.memo(FormattedAttachmentConfidence)
