import React, { useEffect, useState } from 'react';
import { Outlet, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { removeUserLoggedInDetails } from '../services/utils/Helper';

function ProtectedOutlet() {
  const location = useLocation();
  const navigate = useNavigate();

  const [loaded, setLoaded] = useState(false);
  const [user, setUser] = useState('');
  const [token, setToken] = useState('');
  
  useEffect(() => {
    const user = localStorage.getItem("user");
    const token = localStorage.getItem("token");
    const loggedInTime = localStorage.getItem("loggedInTime");

    if (!user || !token) {
      setLoaded(true)
      return;
    }

    const current_time = Date.now();
    const IDLE_TIME_LIMIT = process.env.REACT_APP_IDLE_TIMEOUT * 60;

    if (loggedInTime) {
      const difference = ((current_time - loggedInTime) / 60000);
      if (difference < IDLE_TIME_LIMIT) {
        setToken(token);
        setUser(user);
      } else {
        removeUserLoggedInDetails();
        navigate("/login", { state: { from: location } });
      }
    }

    setLoaded(true);
  }, []);

  return (
      <React.Fragment>
      {
        loaded ?
          (user && token)? <Outlet /> : <Navigate to="/login" state={{ from: location }} /> :
          'Loading....'
      }</React.Fragment>
      
  );
}

export default ProtectedOutlet;
