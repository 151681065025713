import { SliceActionTypes } from "../constants/sliceActionTypes";

const initialState = {
  selectedType: "",
  selectedSlice: "",
  selectedCompany: "All",
  selectedDistributor: "All",
  selectedSource: "All",
  selectedIngestionType: "All",
  selectedStartDate: "",
  selectedEndDate: "",
};

const sliceReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SliceActionTypes.SET_SELECTED_FILTERS:
      return {
        ...state,
        selectedType: payload.type,
        selectedSlice: payload.slice,
        selectedCompany: payload.company,
        selectedDistributor: payload.distributor,
        selectedSource: payload.source,
        selectedIngestionType: payload.ingestionType,
        selectedStartDate: payload.startDate,
        selectedEndDate: payload.endDate,
      };

    case SliceActionTypes.USER_LOGOUT:
      return initialState;

    default:
      return state;
  }
};

export default sliceReducer;
