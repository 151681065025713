import { ComparisonActionTypes } from "../constants/comparisonActionTypes";

export const setSelectedDocumentIds = (ids) => {
  return {
    type: ComparisonActionTypes.SET_SELECTED_DOCUMENT_IDS,
    payload: ids
  }
};

export const removeSelectedDocumentIds = () => {
  return {
    type: ComparisonActionTypes.REMOVE_SELECTED_DOCUMENT_IDS
  }
};