import React from 'react';
import './SubmissionAttachmentPreview.css';
import { BrowserTypes, OfficeFileTypes, GoogleFileTypes }
    from '../../../../services/constants/constants';
import { ProgressSpinner } from 'primereact/progressspinner';
import CustomizedTooltip from '../../../atoms/Tooltip/CustomizedTooltip';
import useAxios from '../../../../hooks/useAxios';

const SubmissionAttachmentPreview = ({ submissionId, documentId, documentName, documentUrl, setPresignedUrl }) => {
    
    const axiosPrivate = useAxios();
    const [previewLoading, setPreviewLoading] = React.useState(false);

    const getAttachmentExtension = () => {
        return documentName
            ?.substring(documentName.lastIndexOf('.') + 1)
            ?.toUpperCase();
    }

    const openDocument = () => {
        // if (documentUrl) {
        //     loadDocumentInNewTab(documentUrl);
        // } else {

            const fetchDocumentPresignedUrl = async () => {
                setPreviewLoading(true);
                const response = await axiosPrivate
                    .get(`submissions/${submissionId}/documents/${documentId}/presignedurl?type=document`)
                    .catch(err => {
                        setPreviewLoading(false);
                        console.log(err)
                    });

                setPreviewLoading(false);
                const presignedUrl = response.data.presignedurl;
                setPresignedUrl(presignedUrl);

                loadDocumentInNewTab(presignedUrl);
            }

            fetchDocumentPresignedUrl();
        // }
    }

    const loadDocumentInNewTab = (presignedUrl) => {
        const extension = getAttachmentExtension();
        const encodedUrl = encodeURIComponent(presignedUrl);

        if (BrowserTypes.indexOf(extension) !== -1) {
            window.open(presignedUrl, '_blank');
        } else {
            // let viewer = '';
            // if (OfficeFileTypes.indexOf(extension) !== -1) {
            //     viewer = `https://view.officeapps.live.com/op/embed.aspx?src=${encodedUrl}`;
            // } else if (GoogleFileTypes.indexOf(extension) !== -1) {
            //     viewer = `https://docs.google.com/gview?url=${encodedUrl}`;
            // }

            // window.open(viewer, '_blank');
            window.open(presignedUrl, '_blank');
        }
    }

    return (
        <React.Fragment>
            {previewLoading ?
                <div className='progress-loader'>
                    <ProgressSpinner style={{ width: '20px', height: '20px' }} strokeWidth='8' fill='var(--surface-ground)' animationDuration='.5s' />
                </div>
                :
                <React.Fragment>
                    <CustomizedTooltip text={`Open the ${getAttachmentExtension()} in new tab`}>
                    <span onClick={() => openDocument()}>
                    <i className="pi pi-eye cursor-pointer"></i>
                    </span>
                    </CustomizedTooltip>
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default React.memo(SubmissionAttachmentPreview)
