import React, { useEffect } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import NavbarRoutes from "../../../../services/utils/NavbarRoutes";
import "./Sidebar.css";
import CustomizedTooltip from "../../../../components/atoms/Tooltip/CustomizedTooltip";
import GuideIcon from "../../../../assets/sidebar/guide.svg";
import FaqIcon from "../../../../assets/sidebar/faq.svg";
import logout from "../../../../assets/sidebar/logout.svg";
import { Badge } from "@mui/material";
import { Avatar } from "@mui/material";
import { styled } from "@mui/material/styles";
import Popover from "@mui/material/Popover";
import useAxios from "../../../../hooks/useAxios";
import { toast } from "react-toastify";
import useLogout from "../../../../hooks/useLogout";
import { Loader } from "../../../../components";


const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: "0.75rem",
  height: "0.75rem",
  border: `1px solid #FFFFFF`,
  background: "#FFFFFF",
}));

const Sidebar = () => {
  const navBarItems = NavbarRoutes();
  const location = useLocation();
  const navigate = useNavigate();
  const axiosPrivate = useAxios();
  const userDetailRef = React.useRef();
  const { logoutUser } = useLogout();
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openPopUp, setOpenPopUp] = React.useState(false);
  const [loggingout, setLoggingout] = React.useState(false);
  const user = localStorage.getItem("user");

  useEffect(() => {
    if (openPopUp === true) {
      if (userDetailRef.current) {
        userDetailRef.current.style.display = "inline";
      }
    } else {
      if (userDetailRef.current) {
        userDetailRef.current.style.display = "none";
      }
    }
  }, [openPopUp]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenPopUp(!openPopUp);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getDoc = (type) => {
    axiosPrivate
      .get(`submissions/S-01/documents/D-01/presignedurl?type=${type}`)
      .then((res) => {
        var createA = document.createElement("a");
        createA.setAttribute("href", res.data.presignedurl);
        createA.setAttribute("target", "_blank");
        createA.setAttribute("rel", "noopener noreferrer");
        createA.click();
      })
      .catch((err) => {
        toast.error("Loading document failed");
      });
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;


  const handleLogout = async () => {
    try {
      setLoggingout(true);
      
      const confirmLogout = await logoutUser();
      if(confirmLogout.status) {
        navigate("/login", { state: { from: location } });
        toast.success("Logged out successfully")
      } else {
        setLoggingout(false);
        toast.error("Failed to logout")
      }
    } catch (error) {
      setLoggingout(false);
      console.log(error);
    }
  };

  const toggleSidebar = () => {
    setSidebarOpen((prevState) => !prevState);
  };

  return (
    <>
      <Loader loading={loggingout} />
      <div className={`sidebar ${sidebarOpen ? "open" : ""}`}>
        <div className="logo-details">
          {sidebarOpen ? (
            <img src={"assets/images/logo.png"} alt="Dociphi" width="60%" />
          ) : (
            <img
              src={"assets/images/cbimage.png"}
              alt="Dociphi"
              width="40"
              height="40"
            />
          )}
        </div>
        <ul className="nav-list">
          <CustomizedTooltip text="Menu">
            <li className={sidebarOpen ? 'menu new-menu-style-open' : 'menu new-menu-style'} onClick={toggleSidebar}>
              <span className="icons menu-icon" style={{ margin: 0 }}>
                <i className="pi pi-bars"></i>
              </span>
            </li>
          </CustomizedTooltip>
          {navBarItems.map((item, index) => {
            return (
              <CustomizedTooltip text={item.tooltip}>
                <NavLink to={item.href} key={index} className={sidebarOpen ? 'new-menu-style-open' : 'new-menu-style'}>
                  {({ isActive }) => (
                    <>
                      <span className="disp-contents">
                        <span className={item.icon_container} style={{ margin: 0 }}>
                          <img
                            src={isActive ? item.activeIcon : item.icon}
                            alt="icon"
                          />
                        </span>
                        {sidebarOpen && (
                          <span className="links_name">{item.label}</span>
                        )}
                      </span>
                    </>
                  )}
                </NavLink>
              </CustomizedTooltip>
            );
          })}
          {/* guide */}
          <CustomizedTooltip text="Guide">
            <li
              className={sidebarOpen ? 'bottom-nav new-menu-style-open' : 'bottom-nav new-menu-style'}
              style={{ bottom: "5rem" }}
              onClick={() => {
                getDoc("agreement");
              }}
            >
              <span className="icons menu-icon" style={{ margin: 0 }}>
                <img src={GuideIcon} alt="guide" />
              </span>
            </li>
          </CustomizedTooltip>
          <CustomizedTooltip text="FAQ">
            <li
              className={sidebarOpen ? 'menu bottom-nav new-menu-style-open' : 'menu bottom-nav new-menu-style'}
              style={{ bottom: "3rem" }}
              onClick={() => {
                getDoc("faq");
              }}
            >
              <span className="icons menu-icon" style={{ margin: 0 }}>
                <img src={FaqIcon} alt="guide" />
              </span>
            </li>
          </CustomizedTooltip>
          <CustomizedTooltip text="Logout">
            <li className={sidebarOpen ? 'menu bottom-nav new-menu-style-open' : 'menu bottom-nav new-menu-style'} onClick={handleClick}>
              <span className="icons menu-icon" style={{ margin: 0 }}>
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={<SmallAvatar alt="logout" src={logout} />}
                >
                  <Avatar
                    alt="Dociphi"
                    sx={{
                      bgcolor: "#24B082",
                      width: "1.5rem",
                      height: "1.5rem",
                    }}
                  >
                    <span className="h2Regular">
                      {user?.split("")[0].toUpperCase()}
                    </span>
                  </Avatar>
                </Badge>
              </span>
            </li>
          </CustomizedTooltip>

          <div className="popup-user-detail" ref={userDetailRef}>
            <span
              className="user-detail-font h3Regular"
              style={{
                color: "#616E84",
                cursor: "default",
                marginBottom: "0.4rem",
              }}
            >
              {user}
            </span>
            <span
              onClick={handleLogout}
              className="user-detail-font h3Regular"
              style={{ color: "#15284B", cursor: "pointer" }}
            >
              Logout
            </span>
          </div>
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
