import React from 'react';
import './SubmissionAttachmentClassification.css';
import { AttachmentStatus, MergeDocument } from '../../../../services/constants/constants';
import CustomizedTooltip from '../../../atoms/Tooltip/CustomizedTooltip';

const SubmissionAttachmentClassification = (props) => {

  const { documentName, classification, classificationTypes, status, editMode } = props;
  const { handleClassificationTypeChange } = props;
  const [value, setValue] = React.useState(classification);

  const classificationTypeChanged = (value) => {
    setValue(value);
    handleClassificationTypeChange(value);
  }

  const getClassificationType = () => {
    if (classification) {
      const classType = classificationTypes.find(
        (x) => x.key.toUpperCase() === classification.toUpperCase()
      )?.value;
      if (
        classType?.toUpperCase() === 'UNKNOWN' &&
        documentName.toUpperCase().endsWith('XLSX')
      ) {
        return '';
      } else if (classification.toUpperCase() === MergeDocument.value.toUpperCase()) {
        return classification;
      }
      else {
        return classType;
      }
    }

    return '';
  }
  return (
    <React.Fragment>
      {editMode ?
        // <Dropdown optionLabel='value' optionValue='value' value={value}
        //   options={classificationTypes} onChange={(e) => classificationTypeChanged(e.value)}
        //   placeholder='Select a classification' />
        <select name="navigateDropdown" id="navigateDropdown" className="dropdown" value={value} onChange={(e) => classificationTypeChanged(e.target.value)} placeholder='Select a classification'>
          {classificationTypes.map((item) => (
            <option className="dropdown-option" value={item.key}>{item.value}</option>
          ))}
        </select>
        :
        <React.Fragment>
          {status !== AttachmentStatus.Received &&
            <React.Fragment>
              <CustomizedTooltip text={getClassificationType()}>
                <span className={`${getClassificationType() === MergeDocument.value && 'merge-document'} truncate label-truncate cls-ptooltip`} style={{ fontSize: '12px', fontWeight: 500 }}>
                  {getClassificationType()}
                </span>
              </CustomizedTooltip>
            </React.Fragment>
          }
        </React.Fragment>
      }
    </React.Fragment >
  )
}

export default React.memo(SubmissionAttachmentClassification);
