import React from 'react';
import useIdleTimeoutV2 from "../../hooks/useIdleTimeoutV2";
import { useLocation, useNavigate } from 'react-router-dom';
import useLogout from '../../hooks/useLogout';
import { toast } from 'react-toastify';

const IDLE_TIME_LIMIT = process.env.REACT_APP_IDLE_TIMEOUT * 60 // in minutes

const IdleSessionTimeout = () => {
  const { isUserIdle } = useIdleTimeoutV2(IDLE_TIME_LIMIT * 60000);
  const navigate = useNavigate();
  const location = useLocation();
  const { logoutUser } = useLogout();

  const logout = async () => {
    try {
      const confirmLogout = await logoutUser();
      if(confirmLogout.status) {
        navigate("/login", { state: { from: location } });
      } else {
        toast.error("Logout Failed.")
      }
    } catch (error) {
      console.log(error);
    }
  };

  // logging out user if screen is idle for x time
  React.useEffect(() => {
    if (isUserIdle) {
      logout();
    }
  }, [isUserIdle]);

  // for logging out if last activity was x time back and tab is closed
  React.useEffect(() => {
    const newTime = Date.now();
    if (localStorage.getItem('loggedInTime')) {
      const difference = ((newTime - localStorage.getItem('loggedInTime')) / 60000);
      if (difference > IDLE_TIME_LIMIT) {
        logout();
      }
    }
  }, []);

  // setting the timer when tab is getting closed
  const setTimerBeforeUnload = () => {
    localStorage.setItem('loggedInTime', Date.now());
  };

  React.useEffect(() => {
    window.addEventListener('beforeunload', (e) => {
      e.preventDefault();
      return setTimerBeforeUnload();
    })
  }, []);
  
  return (
    <></>
  )
}

export default IdleSessionTimeout;
