import { removeUserLoggedInDetails } from "../services/utils/Helper";
import useAxios from "./useAxios";

const useLogout = () => {
  const axiosPrivate = useAxios();

  const confirmLogout = () => {
    return axiosPrivate.get('/auth/token/revoke');
  }

  const logoutUser = async () => {
    try {
      const logout = await confirmLogout();
      if (logout.data.status) {
        removeUserLoggedInDetails();
      }
      return logout.data;
    } catch (error) {
      removeUserLoggedInDetails();
    }
  };
  return { logoutUser };
};

export default useLogout;
