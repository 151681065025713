export const SubmissionActionTypes = {
    SET_SELECTED_SUBMISSION: 'SET_SELECTED_SUBMISSION',
    REMOVE_SELECTED_SUBMISSION: 'REMOVE_SELECTED_SUBMISSION',
    SUBMISSIONS_ERROR : 'SUBMISSIONS_ERROR',
    SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
    SET_EXPANDED_ROWS: 'SET_EXPANDED_ROWS',
    SET_SEARCH_TEXT: 'SET_SEARCH_TEXT',
    SET_SEARCH_TEXT_FINAL: 'SET_SEARCH_TEXT_FINAL',
    SET_FILTER_CURRENT_PAGE: 'SET_FILTER_CURRENT_PAGE',
    SET_FILTER_OPTIONS: 'SET_FILTER_OPTIONS',
    SET_FILTER_OPTIONS_FINAL: 'SET_FILTER_OPTIONS_FINAL',
};