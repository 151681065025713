import React from "react";
import "./SubmissionAttachments.css";
import { SubmissionAttachment } from "./components";
import {
  SubClassificationTypes,
  AttachmentStatus,
  ReadOnlyMode,
  OtherType,
  MergedType,
  SubmissionStatus,
  CanShowComparison
} from "../../services/constants/constants";
import { NestedTableCell } from "../../components/table-style/StyledTableCell";
import { StyledTableContainer } from "../../components/table-style/StyledTableContainer";
import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import ButtonOutlined from "../button/ButtonOutlined";
import { axiosPrivate } from "../../config/axios";
import { toast } from "react-toastify";
import ControlledTooltip from "../atoms/Tooltip/ControlledTooltip";
import { useNavigate } from "react-router-dom";

const SubmissionAttachments = (props) => {
  const {
    submissionId,
    submissionStatus,
    attachments,
    currentPage,
    description,
    isOldSubmission,
    fetchSubmissions,
    isValidSubmission,
    handleExtractionDoneProcess,
    handleClassificationDoneProcess,
    handleMergedClassificationDoneProcess,
    handleStatusUpdate,
    handleSetXmlPreview,
    invalidMessage,
    selectedDocuments,
    setSelectedDocuments,
  } = props;
  const attachmentCompRef = React.useRef([]);
  const [documents, setDocuments] = React.useState(attachments);
  const [allDocsLoaded, setAllDocsLoaded] = React.useState(false);
  const [dowloadAllLoader, setDownloadAllLoader] = React.useState(false);
  const isExtractionDone = React.useRef(false);
  const isClassificationDone = React.useRef(false);

  const navigate = useNavigate();

  const canConfirmAll = () => {
    return (
      documents.some((x) => x.status === AttachmentStatus.Classified)
      && allDocsLoaded
      && !documents.every(x => x.output_json.approved)
      && documents.some(x =>
        x.output_json.type.toUpperCase() !== OtherType.toUpperCase() &&
        x.output_json.type.toUpperCase() !== MergedType.toUpperCase()
      )
    );
  };

  const setXmlPreview = (data) => {
    handleSetXmlPreview(data);
  };

  const hasSubClassification = (type) => {
    return SubClassificationTypes.some(
      (x) => x.key.toUpperCase() === type?.toUpperCase()
    );
  };


  const isClassificationUnknown = (classification) => {
    return classification?.toUpperCase() === "UNKNOWN";
  };

  const handleDocumentClassification = (attachment) => {
    setDocuments((prevState) => {
      const newState = prevState.map((doc) => {
        if (doc.document_id === attachment.document_id) {
          return {
            ...doc,
            status: attachment.status,
            output_json: attachment.output_json,
          };
        }

        return doc;
      });

      handleStatusUpdate(submissionId, newState);

      if (newState.every((x => x.output_json))) {
        setAllDocsLoaded(true);
      }

      if (
        newState.some(x => (
          x.status === AttachmentStatus.Processed &&
          x.output_json !== null &&
          !isExtractionDone.current
        ))
      ) {
        isExtractionDone.current = true;
        handleExtractionDoneProcess(submissionId);
      }

      if (newState.every((x) => x.status === AttachmentStatus.Classified && x.output_json !== null) &&
        !isClassificationDone.current) {
        isClassificationDone.current = true;
        handleClassificationDoneProcess(submissionId);
      }
      return newState;
    });
  };

  const canApprove = (attachment) => {
    if (hasSubClassification()) {
      if (!attachment?.output_json?.sub_type) {
        return false;
      }
    }

    return (
      !attachment?.output_json?.approved &&
      !isClassificationUnknown() &&
      (attachment?.status === AttachmentStatus.Classified ||
        attachment?.status === AttachmentStatus.Extracting ||
        attachment?.status === AttachmentStatus.Processed ||
        attachment?.status === AttachmentStatus.Reviewed)
    );
  };

  const approveAllDocuments = () => {
    for (const [i, doc] of documents.entries()) {
      if (canApprove(doc)) {
        attachmentCompRef.current[i].approveDocument();
      }
    }
  };

  const viewComparison = () => {
    navigate(
      `/home/submissions/${submissionId}/comparison?docs=${selectedDocuments.find(x => x.submission_id === submissionId)?.documents.join(',')}`
    );
  };

  const canViewComparison = () => {
    let flag = false;

    if (submissionStatus === SubmissionStatus.Ready_for_comparison
      || submissionStatus === SubmissionStatus.Reviewed) {
      if (attachments.length > 0) {
        const index = selectedDocuments.findIndex(x => x.submission_id === submissionId);
        if (index !== -1) {
          if (selectedDocuments[index].selectedCount >= 2 && selectedDocuments[index].selectedCount <= 3) {
            flag = true;
          }
        }
      }
    }

    return flag;
  }

  const areAllDocsInvalid = () => {
    if (allDocsLoaded) {
      if (documents.every(x => (x.output_json.type.toUpperCase() === OtherType.toUpperCase()
        || x.output_json.type.toUpperCase() === MergedType.toUpperCase()))) {
        return true;
      }
    }
    return false;
  };

  const getJustifyContent = () => {
    if (allDocsLoaded) {
      if (areAllDocsInvalid()) {
        return 'flex-start';
      }

      if (documents.some(x => x.status === AttachmentStatus.Processed) && CanShowComparison) {
        return 'space-between';
      }
      else if (documents.some(x => x.status === AttachmentStatus.Processed) && !CanShowComparison) {
        return 'flex-end';
      }

      return 'flex-end';
    }
    return 'flex-end';
  };

  const downloadAllDocs = () => {
    setDownloadAllLoader(true);
    axiosPrivate.get(`/submissions/${submissionId}/download`)
      .then((response) => {
        setDownloadAllLoader(false);
        const element = document.createElement('a');
        element.href = response.data.presigned_url;
        element.setAttribute("target", "_blank");
        element.setAttribute("download", `${description}.zip`);
        document.body.appendChild(element);
        element.click();
        toast.success("Documents Downloaded Successfully");
      })
      .catch(() => {
        toast.error("Failed to Download the Document");
        setDownloadAllLoader(false);
      });
  };

  return (
    <React.Fragment>
      <div className="row justify-content-md-center mt-2" style={{ marginInline: 0, width: '100%' }}>
        {
          ReadOnlyMode === true &&
          <div className="download-all-button">
            {
              dowloadAllLoader
                ? <span className="download-text">Downloading...</span>
                : <span className="download-text" onClick={downloadAllDocs}>Download Docs</span>
            }
          </div>
        }
        <React.Fragment>
          <div
            className="pl-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 extra-info"
            style={{
              justifyContent: getJustifyContent()
            }}
          >
            {areAllDocsInvalid() && (
              <span
                className="h3Medium"
                style={{
                  paddingLeft: "16px",
                  color: "#3C4C68",
                  paddingTop: "4px",
                  paddingBottom: "4px",
                  borderLeft: "4px solid #DE350B",
                }}
              >
                Relevant documents required to confirm classification!
              </span>
            )}

            {allDocsLoaded && documents.some(x => x.status === AttachmentStatus.Processed) && CanShowComparison && (
              <ControlledTooltip
                text="Select documents to view comparison (maximum 3 documents)"
                open={true}
                position="right"
              >
                <div>
                  <ButtonOutlined
                    text="View Comparison"
                    onClick={viewComparison}
                    disabled={!canViewComparison()}
                  />
                </div>
              </ControlledTooltip>
            )}
            {ReadOnlyMode === false && canConfirmAll() && (
              <ControlledTooltip
                text="Confirm all classification to proceed for extraction"
                open={true}
                position="left"
              >
                <div>
                  <ButtonOutlined
                    text="Confirm All"
                    onClick={() => approveAllDocuments()}
                    borderColor="#AEB3BC"
                    textColor="#3C4C68"
                  />
                </div>
              </ControlledTooltip>
            )}
          </div>
        </React.Fragment>
        <StyledTableContainer>
          <div>
            <Table>
              <TableHead>
                <TableRow>
                  <NestedTableCell />
                  <NestedTableCell style={{ width: "3rem" }} />
                  <NestedTableCell >Document Name</NestedTableCell>
                  {/* <NestedTableCell>Carrier</NestedTableCell> */}
                  <NestedTableCell>Classification</NestedTableCell>
                  {/* <NestedTableCell>Sub Classification</NestedTableCell> */}
                  <NestedTableCell>Confidence Score</NestedTableCell>
                  <NestedTableCell>Status</NestedTableCell>
                  <NestedTableCell>Action</NestedTableCell>
                  {
                    ReadOnlyMode === true &&
                    <NestedTableCell></NestedTableCell>
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {attachments && attachments.map((attachment, index) => {
                  return (
                    <React.Fragment key={index}>
                      <SubmissionAttachment
                        ref={(element) => {
                          attachmentCompRef.current[index] = element;
                        }}
                        submissionId={submissionId}
                        attachment={attachment}
                        attachments={attachments}
                        setXmlPreview={setXmlPreview}
                        currentPage={currentPage}
                        isUploaded={true}
                        isOldSubmission={isOldSubmission}
                        handleDocumentClassification={
                          handleDocumentClassification
                        }
                        handleMergedClassificationDoneProcess={handleMergedClassificationDoneProcess}
                        fetchSubmissions={fetchSubmissions}
                        selectedDocuments={selectedDocuments}
                        setSelectedDocuments={setSelectedDocuments}
                      />
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </StyledTableContainer>
      </div>
    </React.Fragment>
  );
};

export default React.memo(SubmissionAttachments);
