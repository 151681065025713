import { ExtractionActionTypes } from '../constants/extractionActionTypes';

export const setExtractionDetails = (extraction) => {
  return {
    type: ExtractionActionTypes.SET_SELECTED_EXTRACTION,
    payload: extraction
  }
};

export const removeExtractionDetails = () => {
  return {
    type: ExtractionActionTypes.REMOVE_SELECTED_EXTRACTION
  }
};

export const setSelectedDocumentId = (id) => {
  return {
    type: ExtractionActionTypes.SET_SELECTED_DOCUMENT_ID,
    payload: id
  }
};

export const removeSelectedDocumentId = () => {
  return {
    type: ExtractionActionTypes.REMOVE_SELECTED_DOCUMENT_ID
  }
};