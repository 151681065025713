import React, { useEffect, useRef, useState } from 'react';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import { confirmPopup } from "primereact/confirmpopup";
import { ConfirmPopup } from 'primereact/confirmpopup';
import useAxios from '../../hooks/useAxios';
import useIdleTimeout from '../../hooks/useIdleTimeout';
import ButtonFilled from '../button/ButtonFilled';
import {
  IdleUserTimeout,
  SubmissionStatus,
  TimerShortPushingInterval,
  WarningTimeout,
} from '../../services/constants/constants';
import { formattedReviewSeconds, formattedTime } from '../../services/utils/Helper';
import {
  PauseActiveIcon,
  PauseInactiveIcon,
  ResumeActiveIcon,
  ResumeInactiveIcon,
  ResetActiveIcon,
  ResetInactiveIcon,
  TimerIcon,
} from '../../assets/packet-timer';
import './PacketTimer.css';
import useTabChange from '../../hooks/useTabChange';
import { useDispatch, useSelector } from 'react-redux';
import { setIsResetPopupOpen, setIsTimerPaused, setIsTimeZero, setReviewTime } from '../../redux/actions/timerActions';
import { toast } from 'react-toastify';
import { setSelectedSubmission } from '../../redux/actions/submissionActions';
import ReviewCompletedIcon from '../../assets/reviewCompletedIcon.svg';
import _ from 'lodash';

const PacketTimer = (props) => {
  const {
    setLoader, showCompleteReview = true,
  } = props;

  const submission = useSelector(state => state.submissions.submission);
  const timer = useSelector(state => state.timer);
  const dispatch = useDispatch();

  const [isTimerActive, setIsTimerActive] = useState(false);
  const [time, setTime] = useState(0);
  const [warningTime, setWarningTime] = useState(0);
  const [loading, setLoading] = useState(false);
  const [idleConfirmPopupOpen, setIdleConfirmPopupOpen] = useState(false);
  const [resetConfirmPopupOpen, setResetConfirmPopupOpen] = useState(false);
  const timeRef = useRef(time);
  const warningTimeRef = useRef(warningTime);
  const timerElementRef = useRef(null);
  const idleTimeoutInterval = useRef(null);
  const hardPaused = useRef(true);
  const firstRender = useRef(true);

  const axiosPrivate = useAxios();
  const { isUserIdle, resetTimeout } = useIdleTimeout(IdleUserTimeout);
  const isTabChanged = useTabChange();

  const fetchInitialTimerValue = (config) => {
    axiosPrivate.get(`submissions/${submission.submission_id}`, config)
      .then((response) => {
        setLoading(false);
        setTime(response.data.review_time);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const startTimer = () => {
    setIsTimerActive(true);
    dispatch(setIsTimerPaused(false));
    hardPaused.current = false;
  };

  const pauseTimer = () => {
    setIsTimerActive(false);
    saveTimeToDB();
    dispatch(setIsTimerPaused(true));
  };

  const handleReset = () => {
    setResetConfirmPopupOpen(true);
    pauseTimer();
  };

  const resetTimer = () => {
    setIsTimerActive(false);
    setTime(0);
    saveTimeToDB(0);
    hardPaused.current = true;
  };

  const saveTimeToDB = (explicitTime = undefined) => {
    if (!submission.submission_id) return;

    const timeValue = typeof explicitTime !== 'undefined' ? explicitTime : timeRef.current;
    const payload = {
      field: 'review_time',
      value: timeValue,
    };

    axiosPrivate.patch(`submissions/${submission.submission_id}`, payload)
      .then((response) => {
        if (timeValue === 0) {
          dispatch(setReviewTime(0));
        }
      })
      .catch((err) => { });
  };

  const handleWarningTimer = () => {
    if (warningTimeRef.current >= WarningTimeout) {
      clearInterval(idleTimeoutInterval.current);
      setIdleConfirmPopupOpen(false);
      setIsTimerActive(false);
      dispatch(setIsTimerPaused(true));

      if ((timeRef.current - WarningTimeout - IdleUserTimeout / 1000) >= 0) {
        saveTimeToDB(timeRef.current - WarningTimeout - IdleUserTimeout / 1000);
        setTime(prevState => (prevState - WarningTimeout - IdleUserTimeout / 1000));
      } else {
        saveTimeToDB(0);
        setTime(0);
      }

      setWarningTime(0);
      hardPaused.current = true;
    } else {
      setWarningTime(prevState => prevState + 1);
    }
  };

  const handleUserStillHere = () => {
    setIdleConfirmPopupOpen(false);
    clearInterval(idleTimeoutInterval.current);
    setWarningTime(0);
  };

  const completeReviewPopup = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Are you sure you want to complete the review ?",
      rejectLabel: "Cancel",
      acceptLabel: "Confirm",
      accept: () => {
        pauseTimer();
        completeReview();
      },
      reject: () => { },
    });
  };

  const completeReview = () => {
    const payload = {
      field: 'status',
      value: 'reviewed',
    };

    setLoader(true);
    axiosPrivate.patch(`/submissions/${submission.submission_id}`, payload)
      .then(async () => {
        const res = await axiosPrivate.get(`/submissions/${submission.submission_id}`);
        dispatch(setSelectedSubmission(res.data));
        setLoader(false);
        toast.success("Review completed successfully!");
      })
      .catch((error) => {
        setLoader(false);
        toast.error("Failed to complete the review!");
      });
  };

  const getPacketTime = () => {
    const value = formattedTime(time);

    return (
      <React.Fragment>
        <span className="digits">
          {value[0]}:
        </span>
        <span className="digits">
          {value[1]}:
        </span>
        <span className="digits mili-sec">
          {value[2]}
        </span>
      </React.Fragment>
    );
  }

  useEffect(() => {
    if (isUserIdle && isTimerActive && !isTabChanged && !idleConfirmPopupOpen) {
      setIdleConfirmPopupOpen(true);
      idleTimeoutInterval.current = setInterval(handleWarningTimer, 1000);
    }
  }, [isUserIdle]);

  useEffect(() => {
    warningTimeRef.current = warningTime;
  }, [warningTime]);

  useEffect(() => {
    let timeInterval = null;
    let shortPushInterval = null;

    if (isTimerActive) {
      shortPushInterval = setInterval(saveTimeToDB, TimerShortPushingInterval);
      timeInterval = setInterval(() => {
        setTime(prevState => prevState + 1);
      }, 1000);
    } else {
      clearInterval(timeInterval);
      clearInterval(shortPushInterval);
    }

    return () => {
      clearInterval(timeInterval);
      clearInterval(shortPushInterval);
    };
  }, [isTimerActive]);

  useEffect(() => {
    timeRef.current = time;

    if (time === 0) {
      if (!timer.isTimeZero) {
        dispatch(setIsTimeZero(true));
      }
    } else if (timer.isTimeZero) {
      dispatch(setIsTimeZero(false));
    }
  }, [time]);

  useEffect(() => {
    if (isTabChanged) {
      if (isTimerActive) {
        if (idleConfirmPopupOpen) {
          setIdleConfirmPopupOpen(false);
          clearInterval(idleTimeoutInterval.current);
          setWarningTime(0);
          resetTimeout();
        }
        pauseTimer();
      }
    } else if (typeof isTabChanged !== 'undefined') {
      setLoading(true);
      setTimeout(() => {
        fetchInitialTimerValue();
      }, 1000);
    }
  }, [isTabChanged]);

  useEffect(() => {
    dispatch(setIsResetPopupOpen(resetConfirmPopupOpen));
  }, [resetConfirmPopupOpen]);

  useEffect(() => {
    if (submission.review_time && firstRender.current) {
      setTime(submission.review_time);
      dispatch(setIsTimeZero(submission.review_time === 0));
      firstRender.current = false;
    }

    return () => {
      saveTimeToDB();
      clearInterval(idleTimeoutInterval.current);
    };
  }, [submission]);

  return (
    !_.isEmpty(submission) && (
      submission.status.toLowerCase() === SubmissionStatus.Reviewed.toLowerCase() ? (
        <div style={{ marginRight: '8px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={ReviewCompletedIcon} alt="check" />
            <span style={{ color: '#2D4081', paddingLeft: '0.5rem' }} className="h3Medium">Review Completed!</span>
          </div>
          <div className="review-time">
            {formattedReviewSeconds(submission.review_time)}
          </div>
        </div>
      ) : (
        <div className='timer-container'>
          <div className="timer" ref={timerElementRef}>
            <img src={TimerIcon} alt="" />
            {loading ?
              <React.Fragment>
                <p style={{ fontSize: '13.5px', margin: 0, width: '4rem' }}>Loading...</p>
              </React.Fragment>
              :
              <React.Fragment>
                <div style={{ width: '4rem' }}>
                  {getPacketTime()}
                </div>
              </React.Fragment>
            }
            <>
              {isTimerActive && submission.allDocumentsLoaded && !loading
                ? <IconButton sx={{ padding: 0 }} disableRipple className="timer-actions"><img src={PauseActiveIcon} alt="" onClick={() => { hardPaused.current = true; pauseTimer(); }} /></IconButton>
                : <IconButton sx={{ padding: 0 }} disableRipple disabled><img src={PauseInactiveIcon} alt="" /></IconButton>}
              {isTimerActive || !submission.allDocumentsLoaded || loading
                ? <IconButton sx={{ padding: 0 }} disableRipple disabled><img src={ResumeInactiveIcon} alt="" /></IconButton>
                : <IconButton sx={{ padding: 0 }} disableRipple className="timer-actions"><img src={ResumeActiveIcon} alt="" onClick={startTimer} /></IconButton>
              }
              {time === 0 || !submission.allDocumentsLoaded || loading
                ? <IconButton sx={{ padding: 0 }} disableRipple disabled><img src={ResetInactiveIcon} alt="" /></IconButton>
                : <IconButton sx={{ padding: 0 }} disableRipple className="timer-actions"><img src={ResetActiveIcon} alt="" onClick={handleReset} id="resetButton" /></IconButton>}
            </>
            <ConfirmPopup
              target={document.getElementById('resetButton')}
              visible={resetConfirmPopupOpen}
              onHide={() => setResetConfirmPopupOpen(false)}
              message={<><p className='confirm-popup-header'>Confirm Reset</p><p className='confirm-popup-content h3Regular'>Are you sure you want to reset the timer?</p></>}
              accept={resetTimer}
              reject={() => { if (!hardPaused.current) startTimer(); }}
            />
          </div>
          <Popover
            open={idleConfirmPopupOpen}
            anchorEl={timerElementRef.current}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <div className="popup-style">
              <p className="popup-title">Are you still there?</p>
              <p className='popup-warning-text h3Regular'>
                If not we'll pause this session in <span className='popup-warning-text h3Medium'>{WarningTimeout - warningTime}</span>
              </p>
              <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'flex-end' }}>
                <ButtonFilled
                  text="I'm Here"
                  height="2rem"
                  onClick={handleUserStillHere}
                />
              </div>
            </div>
          </Popover>
          {showCompleteReview &&
            <ButtonFilled
              text="Complete Review"
              width="9.25rem"
              height="2rem"
              onClick={completeReviewPopup}
              borderRadius="0px 4px 4px 0px"
              disabled={time === 0}
            />
          }
        </div>
      )
    )
  );
}

export default PacketTimer;
