import React from "react";
import { CloseOutlined, ArrowsAltOutlined, ShrinkOutlined } from '@ant-design/icons';
import IconButton from '@mui/material/IconButton';
import pdfIcon from '../../../../assets/dashboard/pdf.svg';
import './PdfEditor.css'
import SideDrawer from "./SideDrawer";

const PdfEditor = (props) => {
  const { setFullScreen, fullScreen, handleClose, submissionId,
    documents, parentDocumentId, fetchSubmissions } = props;

  const handleMaximize = () => {
    setFullScreen(true);
  };

  const handleMinimize = () => {
    setFullScreen(false);
  };

  //   const handleCloseDrawer = () => {
  //     setIsOpenDrawer(false);
  //   };

  return (
    <>
      <div className="pdf-editor-container">
        <div className="pdf-editor-header">
          <div>
            <img src={pdfIcon} alt="pdf" style={{ margin: '0rem 1rem' }} />
            <span className="h3Medium" style={{ color: 'white' }}>PDF Editor
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <span className="h3Regular">2021_0408_WC_Policy.pdf</span>
            </span>
          </div>
          <div>
            {!fullScreen ? (
              <IconButton onClick={handleMaximize}>
                <ArrowsAltOutlined style={{ color: 'white' }} />
              </IconButton>
            ) : (
              <IconButton onClick={handleMinimize}>
                <ShrinkOutlined style={{ color: 'white' }} />
              </IconButton>
            )}
            <IconButton onClick={handleClose}>
              <CloseOutlined style={{ color: 'white' }} />
            </IconButton>
          </div>
        </div>
        <div className="pdf-editor-content">
          <SideDrawer
            submissionId={submissionId}
            fullScreen={fullScreen}
            documents={documents.filter((item) => item.parent_document_id === parentDocumentId)}
            parentDocumentId={parentDocumentId}
            handleClose={handleClose}
            fetchSubmissions={fetchSubmissions}
          />
        </div>
      </div>
    </>
  )
}
export default PdfEditor;
