import { HitlStatus } from '../constants/constants';
import DOMPurify from 'dompurify';
import moment from 'moment';

export function storeUserLoggedInDetails(email, refreshToken, token, time) {
    localStorage.setItem("user", email);
    localStorage.setItem("refreshToken", refreshToken);
    localStorage.setItem("token", token);
    localStorage.setItem('loggedInTime', time);
}

export function removeUserLoggedInDetails() {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("loggedInTime");
}

export function isEmpty(obj = {}) {
    return Object.keys(obj).length === 0
}

export function isString(value) {
    return typeof value === 'string' || value instanceof String
}

export function isNumber(value) {
    return typeof value == 'number' && !isNaN(value)
}

export function isBoolean(value) {
    return value === true || value === false
}

export function isNil(value) {
    return typeof value === 'undefined' || value === null
}

export function isDateString(value) {
    if (!isString(value)) return false

    return value.match(/^\d{2}-\d{2}-\d{4}$/)
}

export function convertDateString(value) {
    return value.substr(6, 4) + value.substr(3, 2) + value.substr(0, 2)
}

export function toLower(value) {
    if (isString(value)) {
        return value.toLowerCase()
    }
    return value
}

export function convertType(value) {
    if (isNumber(value)) {
        return value.toString()
    }

    if (isDateString(value)) {
        return convertDateString(value)
    }

    if (isBoolean(value)) {
        return value ? '1' : '-1'
    }

    return value
}

export function titlecase(input) {
    return input?.replace(/\w\S*/g, (txt => txt[0].toUpperCase() + txt.substr(1).toLowerCase()));;
}

export function removeUnderscore(input) {
    return input.replaceAll('_', ' ');
}

export function roundToTwo(num) {
    return +(Math.round(num + 'e+2') + 'e-2');
}

export function filterRows(rows, filters) {
    if (isEmpty(filters)) return rows

    return rows.filter((row) => {
        return Object.keys(filters).every((accessor) => {
            const value = row[accessor]
            const searchValue = filters[accessor]

            if (isString(value)) {
                return toLower(value).includes(toLower(searchValue))
            }

            if (isBoolean(value)) {
                return (searchValue === 'true' && value) || (searchValue === 'false' && !value)
            }

            if (isNumber(value)) {
                return value === searchValue
            }

            return false
        })
    })
}

export function sortRows(rows, sortBy, orderBy) {
    if (orderBy === 'asc') {
        return rows.sort(
            (a, b) => 0 - (a[sortBy] > b[sortBy] ? -1 : 1)
        );
    } else {
        return rows.sort(
            (a, b) => 0 - (a[sortBy] > b[sortBy] ? 1 : -1)
        );
    }
}

export function paginateRows(sortedRows, activePage, rowsPerPage) {
    return [...sortedRows].slice((activePage - 1) * rowsPerPage, activePage * rowsPerPage)
}

export function formatNumberToPercentage(number) {
    return `${(number * 100).toFixed(2)}%`
}

export function capitalizeFirstLetter(inputString) {
    if (!inputString) {
        return inputString;
    }

    const words = inputString.split(' ');
    const wordArr = words.map(word => word[0].toUpperCase() + word.slice(1).toLowerCase());
    return wordArr.join(' ');
}

export function cleanString(inputString) {
    if (!inputString) {
        return inputString;
    }
    const cleanedString = inputString.replace(/[^a-z0-9]+/gi, '');
    return cleanedString;
}

export function sanitizeString(inputString) {
    if (!inputString) {
        return inputString;
    }
    const cleanedString = inputString.replace(/[:"]/gi, '');
    return cleanedString.trimEnd();
}

export function omitProperties(obj, propertyList) {
    let updatedObj = Object.assign({}, obj);
    for (let property of propertyList) delete updatedObj[property];

    return updatedObj;
}

export function validateDescription(value) {
    if (value?.trim()?.length === 0 || value?.length < 5 || value?.length > 200 || value.search(/^[a-zA-Z0-9: _-]+$/i) === -1) {
        return false;
    }
    return true;

}
export function validateNumber(value) {
    if (value.search(/^\d+$/) === -1 || value?.trim()?.length === 0) {
        return false;
    }
    return true;
}

export function formattedReviewSeconds(totalSeconds, skipZeros = false) {
    const values = formattedTime(totalSeconds);

    const hours = +values[0];
    const minutes = +values[1];
    const secs = +values[2];

    let hoursTemp;
    let minutesTemp;
    let secsTemp;

    if (!skipZeros) {
        hoursTemp = `${hours} ${hours === 1 ? 'hr' : 'hrs'}`;
        minutesTemp = `${minutes} ${minutes === 1 ? 'min' : 'mins'}`;
        secsTemp = `${secs} ${secs === 1 ? 'sec' : 'secs'}`;
    } else {
        hoursTemp = hours !== 0 ? `${hours} ${hours === 1 ? 'hr' : 'hrs'}` : '';
        minutesTemp = minutes !== 0 ? `${minutes} ${minutes === 1 ? 'min' : 'mins'}` : '';
        secsTemp = `${secs} ${secs === 1 ? 'sec' : 'secs'}`;
    }

    return `${hoursTemp} ${minutesTemp} ${secsTemp}`;
}

export function formattedTime(totalSeconds) {
    const totalMs = totalSeconds * 1000;
    const result = new Date(totalMs).toISOString().slice(11, 19);
    const values = result.split(':');

    return values;
}

export function hitlShouldShow(allowHitl) {
    if (allowHitl !== HitlStatus.HIDE) {
        return true;
    }
    return false;
}

export function hitlShouldEnable(allowHitl) {
    if (allowHitl === HitlStatus.ENABLE) {
        return true;
    }
    return false;
}

export function hitlShouldEnableOrHide(allowHitl) {
    if (allowHitl === HitlStatus.ENABLE || allowHitl === HitlStatus.HIDE) {
        return true;
    }
    return false;
}

export function hitlShouldDisable(allowHitl) {
    if (allowHitl === HitlStatus.DISABLE) {
        return true;
    }
    return false;
}

export function hitlShouldDisableOrHide(allowHitl) {
    if (allowHitl === HitlStatus.DISABLE || allowHitl === HitlStatus.HIDE) {
        return true;
    }
    return false;
}

// formatDateToGMT
export function formatDateToGMT(date) {
    const inputDate = moment(date);
    const gmtDate = inputDate.utc();
    const formattedDate = gmtDate.format("ddd, DD MMM YYYY HH:mm:ss [GMT]");
    return formattedDate;
}

// round up float seconds to minutes time 
export function roundUpFloatTime(time) {
    const number = time / 60;
    const intPart = Math.floor(number);
    const fractionPart = number - intPart;
    if (fractionPart.toFixed(2) >= 0.60) return +(intPart + 1);
    else return +number.toFixed(2);
}

// for source display key in full form
export function formatSourceDisplayKeyAbbrFullForm(key) {
    const convertToAbbrFullForm = {
        'PA - SS': 'PharmAnalytics - Sales & Stock (PA - SS)',
        'PA - PWS': 'PharmAnalytics - Party Wise Sales (PA - PWS)',
        'PC - INV': 'PharmConnect - Invoices (PC - INV)',
        'PC - OD': 'PharmConnect - Order Details (PC - OD)',
        'PR - OD': 'PharmRetail - Order Details (PR - OD)',
        'PC - RO': 'PharmConnect - Return Orders (PC - RO)'
    };

    const displayKey = convertToAbbrFullForm[key] || key;
    return displayKey;
}

// for source display key in short form
export function formatSourceDisplayKeyShortForm(key) {
    const convertToShortForm = {
        'PA - Sales & Stock': 'PA - SS',
        'PA - Party Wise Sales': 'PA - PWS',
        'PC - Invoices': 'PC - INV',
        'PC - Orders': 'PC - OD',
        'PR - Orders': 'PR - OD',
        'PC - Return Orders': 'PC - RO'
    };

    const displayKey = convertToShortForm[key] || key;
    return displayKey;
}

export function formatSourceDisplayKeyFullForm(key) {
    const convertToFullForm = {
        'PA - SS': 'PA - Sales & Stock',
        'PA - PWS': 'PA - Party Wise Sales',
        'PC - INV': 'PC - Invoices',
        'PC - OD': 'PC - Orders',
        'PR - OD': 'PR - Orders',
        'PC - RO': 'PC - Return Orders'
    };

    const displayKey = convertToFullForm[key] || key;
    return displayKey;
}

// utc to browser time conversion
export function UTCToBrowserTime(utcDate) {
    const date = new Date(utcDate);
    return date.toString();
};

// get timezone as per browser like +05:30, -05:30
export function getTimeZone() {
    const offset = new Date().getTimezoneOffset();
    const o = Math.abs(offset);
    return `${(offset < 0 ? '+' : '-') + (`00${Math.floor(o / 60)}`).slice(-2)}:${(`00${o % 60}`).slice(-2)}`;
};

// get date range as per specific timeframes
export function getDates(timeframe) {
    const timezone = getTimeZone();
    const currentDate = new Date();

    if (timeframe === 'today') {
        const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0, 0);
        const endDate = new Date(startDate.getTime() + 24 * 60 * 60 * 1000);
        return {
            startDate: moment(startDate).format('YYYY-MM-DD HH:mm:ss') + timezone,
            endDate: moment(endDate).format('YYYY-MM-DD HH:mm:ss') + timezone,
        };
    }
    if (timeframe === 'this_week') {
        const startDayOfWeek = 1;
        const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - (currentDate.getDay() - startDayOfWeek + 7) % 7, 0, 0, 0, 0);
        const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + (startDayOfWeek + 6 - currentDate.getDay()) % 7, 0, 0, 0, 0);
        endDate.setDate(endDate.getDate() + 1);
        return {
            startDate: moment(startDate).format('YYYY-MM-DD HH:mm:ss') + timezone,
            endDate: moment(endDate).format('YYYY-MM-DD HH:mm:ss') + timezone,
        };
    }
    if (timeframe === 'this_month') {
        const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1, 0, 0, 0, 0);
        const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1, 0, 0, 0, 0);
        return {
            startDate: moment(startDate).format('YYYY-MM-DD HH:mm:ss') + timezone,
            endDate: moment(endDate).format('YYYY-MM-DD HH:mm:ss') + timezone,
        };
    }
    return null;
}

// formatting custom date
export function formatCustomDate(date) {
    const timezone = getTimeZone();
    const dateObj = new Date(date);
    const year = dateObj.getFullYear().toString().padStart(2, '0');
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObj.getDate().toString().padStart(2, '0');
    const hours = dateObj.getHours().toString().padStart(2, '0');
    const minutes = dateObj.getMinutes().toString().padStart(2, '0');
    const seconds = dateObj.getSeconds().toString().padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}` + timezone;
}

// get difference of days between start and end date
export function getDaysDifference(startDate, endDate) {
    function parseDate(input) {
        var parts = input.match(/(\d+)/g);
        return new Date(parts[0], parts[1] - 1, parts[2]);
    }

    const start = parseDate(startDate);
    const end = parseDate(endDate);

    const startMs = start.getTime();
    const endMs = end.getTime();

    const diffMs = endMs - startMs;
    const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
    return diffDays;
}

// get formatted time to show on UI
export function getFormattedTimeRange(timeframe, dateValue, dateRange = '') {
    if (timeframe === 'today') {
        return moment(dateValue).format('YYYY-MM-DD hh:mm a');
    } else if (timeframe === 'this_week' || timeframe === 'this_month') {
        return moment(dateValue).format('YYYY-MM-DD');
    } else if (timeframe === 'custom') {
        if (dateRange < 1) {
            return moment(dateValue).format('YYYY-MM-DD hh:mm a');
        } else if (dateRange <= 1) {
            return moment(dateValue).format('YYYY-MM-DD hh a');
        } else if (dateRange > 1 && dateRange <= 31) {
            return moment(dateValue).format('YYYY-MM-DD');
        } else if (dateRange > 31 && dateRange <= 365) {
            return moment(dateValue).format('MMM-YYYY');
        } else if (dateRange > 365) {
            return moment(dateValue).format('YYYY');
        }
    }
    return null;
}

export function formatDate(date) {
    const dateObj = new Date(date);
    const year = dateObj.getFullYear().toString().padStart(2, '0');
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObj.getDate().toString().padStart(2, '0');
    let hours = dateObj.getHours();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    hours = hours.toString().padStart(2, '0');

    const minutes = dateObj.getMinutes().toString().padStart(2, '0');
    const seconds = dateObj.getSeconds().toString().padStart(2, '0');

    return `${month}-${day}-${year} ${hours}:${minutes}:${seconds} ${ampm}`;
}

// format title case -> snake case
export function titleCaseToSnakeCase(titleCaseStr) {
    return titleCaseStr.replace(/\s+/g, '_').toLowerCase();
}

export function sanitizeInput(userInput) {
    return DOMPurify.sanitize(userInput);
}

export function emailValidation(email) {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
}

export function getDocumentExtension(documentName) {
    return documentName
        ?.substring(documentName.lastIndexOf('.') + 1)
        ?.toUpperCase();
}