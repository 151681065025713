import { SubmissionActionTypes } from '../constants/submissionActionTypes';

export const setSelectedSubmission = (submission) => {
    return {
        type: SubmissionActionTypes.SET_SELECTED_SUBMISSION,
        payload: submission
    }
}

export const removeSelectedSubmission = () => {
    return {
        type: SubmissionActionTypes.REMOVE_SELECTED_SUBMISSION
    }
}

export const setCurrentPage = (page_number) => {
    return {
        type: SubmissionActionTypes.SET_CURRENT_PAGE,
        payload: page_number
    }
}

export const setExpandedRows = (row) => {
    return {
        type: SubmissionActionTypes.SET_EXPANDED_ROWS,
        payload: row
    }
}

export const setSearchText = (searchText) => {
    return {
        type: SubmissionActionTypes.SET_SEARCH_TEXT,
        payload: searchText
    }
}

export const setSearchTextFinal = (searchTextFinal) => {
    return {
        type: SubmissionActionTypes.SET_SEARCH_TEXT_FINAL,
        payload: searchTextFinal
    }
}

export const setFilterCurrentPage = (page_number) => {
    return {
        type: SubmissionActionTypes.SET_FILTER_CURRENT_PAGE,
        payload: page_number
    }
}

export const setFilterOptions = (filters) => {
    return {
        type: SubmissionActionTypes.SET_FILTER_OPTIONS,
        payload: filters
    }
}

export const setFilterOptionsFinal = (finalFilters) => {
    return {
        type: SubmissionActionTypes.SET_FILTER_OPTIONS_FINAL,
        payload: finalFilters
    }
}
