import { SubmissionActionTypes } from "../constants/submissionActionTypes";

const initialState = {
  submission: {},
  expandedRows: [],
  currentPage: 1,
  searchText: "",
  searchTextFinal: "",
  filterCurrentPage: 1,
  filterOptions: {
    start_date: null,
    end_date: null,
    status: "",
    source: "",
  },
  filterOptionsFinal: {
    start_date: null,
    end_date: null,
    status: "",
    source: "",
  },
};

const submissionsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SubmissionActionTypes.SET_SELECTED_SUBMISSION:
      return { ...state, submission: payload };

    case SubmissionActionTypes.REMOVE_SELECTED_SUBMISSION:
      return { ...state, submission: {} };

    case SubmissionActionTypes.SUBMISSIONS_ERROR:
      return { ...state, submissions: {} };

    case SubmissionActionTypes.SET_CURRENT_PAGE:
      return { ...state, currentPage: payload };

    case SubmissionActionTypes.SET_EXPANDED_ROWS:
      return { ...state, expandedRows: payload };

    case SubmissionActionTypes.SET_SEARCH_TEXT:
      return { ...state, searchText: payload };

    case SubmissionActionTypes.SET_SEARCH_TEXT_FINAL:
      return { ...state, searchTextFinal: payload };

    case SubmissionActionTypes.SET_FILTER_CURRENT_PAGE:
      return { ...state, filterCurrentPage: payload };

    case SubmissionActionTypes.SET_FILTER_OPTIONS:
      return { ...state, filterOptions: payload };

    case SubmissionActionTypes.SET_FILTER_OPTIONS_FINAL:
      return { ...state, filterOptionsFinal: payload };

    default:
      return state;
  }
};

export default submissionsReducer;
