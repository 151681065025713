import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  buttonStyle: {
    color: '#F5F8FA',
    border: '1.5px solid #2D4081',
    borderRadius: '0.25rem',
    textTransform: 'none',
    height: '2.5rem',
    padding: '0.5rem 1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    backgroundColor: '#2D4081',
    '&:hover': {
      backgroundColor: '#395DAB',
    },
    '&:active': {
      backgroundColor: '#2D4081',
    },
    '&:disabled': {
      backgroundColor: '#AEB3BC',
      border: '1.5px solid #AEB3BC',
      cursor: 'not-allowed',
    },
  },
}));
function ButtonFilled(props) {
  const {
    text, onClick, disabled = false, icon, width = '', height = '', borderRadius = null, background= '#2D4081', color= '#F5F8FA', fontWeight= 500,
  } = props;
  const classes = useStyles();
    return (
    <div>
      <button
        type="button"
        className={`${classes.buttonStyle} ${'h3Medium'}`}
        onClick={onClick}
        disabled={disabled}
        style={{ width: width, height: height, borderRadius: borderRadius ? borderRadius : '0.25rem' }}
      >
        <span>{icon && <img src={icon} alt=""  style={{ display: 'flex', marginRight: '0.5rem'}}/>}</span>
        {text}
      </button>
    </div>
  );
}
export default ButtonFilled;
