import React from "react";
import { Routes as ViewsRoutes, BrowserRouter, Route, Navigate } from "react-router-dom";

import { Error, Unauthorized } from "../components";
import MainLayout from "../layouts/main/MainLayout";
import ProtectedOutlet from "./ProtectedOutlet";
import { UserAuthContextProvider } from "../context/UserAuthContext";
import TabLayout from "../layouts/tab-layout/TabLayout";

const LazyLogin = React.lazy(() => import("../views/login/Login"));
const LazyFileSubmissions = React.lazy(() => import("../views/file-submissions/FileSubmissions"));
const LazyUploadDocuments = React.lazy(() => import("../views/upload-documents/UploadDocuments"));
const LazyDocumentDetails = React.lazy(() => import("../views/document-details/DocumentDetails"));
const LazyDocumentEnrichment = React.lazy(() =>
  import("../views/document-enrichment/DocumentEnrichment")
);
const LazyDocumentComparison = React.lazy(() =>
  import("../views/document-comparison/DocumentComparison")
);
const LazyDocumentComparisonFire = React.lazy(() =>
  import("../views/hdfc-fire/document-comparison/DocumentComparison")
);
const LazyInsightDashboard = React.lazy(() =>
  import("../views/insights-dashboard/InsightsDashboard")
);
// const LazySubmissionValidation = React.lazy(() =>
//   import("../views/submission-validation/SubmissionValidation")
// );
const LazySubmissionValidation = React.lazy(() =>
  import("../views/document-validation/DocumentDetails")
);
const LazyDocumentManagement = React.lazy(() =>
  import("../views/document-management/DocumentManagement")
);
const LazyTermsConditions = React.lazy(() => import("../views/terms-conditions/TermsConditions"));

function Routes() {
  return (
    <BrowserRouter>
      <UserAuthContextProvider>
        <ViewsRoutes>
          {/* Public Routes */}
          <Route path="*" element={<Navigate to="/login" />} />
          <Route path="error" element={<Error />} />
          <Route path="unauthorized" element={<Unauthorized />} />
          <Route
            path="/home/fire/submissions/:submissionId/comparison"
            element={
              <React.Suspense fallback={<>...</>}>
                <LazyDocumentComparisonFire />
              </React.Suspense>
            }
          />
          <Route
            path="/login"
            element={
              <React.Suspense fallback={<>...</>}>
                <LazyLogin />
              </React.Suspense>
            }
          />

          {/* Protected Routes */}
          <Route element={<ProtectedOutlet />}>
            <Route
              path="/terms"
              element={
                <React.Suspense fallback={<>...</>}>
                  <LazyTermsConditions />
                </React.Suspense>
              }
            />
            <Route path="/" element={<MainLayout />}>
              <Route index element={<Navigate to="/home/file-submissions" />} />
              <Route path="/home" element={<Navigate to="/home/file-submissions" replace />} />
              <Route
                path="/home/upload-documents"
                element={
                  <React.Suspense fallback={<>...</>}>
                    <LazyUploadDocuments />
                  </React.Suspense>
                }
              />
              <Route
                path="/home/file-submissions"
                element={
                  <React.Suspense fallback={<>...</>}>
                    <LazyFileSubmissions />
                  </React.Suspense>
                }
              />
              <Route
                path="/home/insights-dashboard"
                element={
                  <React.Suspense fallback={<>...</>}>
                    <LazyInsightDashboard />
                  </React.Suspense>
                }
              />
              {/* <Route
                path="/home/document-management"
                element={
                  <React.Suspense fallback={<>...</>}>
                    <LazyDocumentManagement />
                  </React.Suspense>
                }
              /> */}
              <Route path="/home/submissions/:submissionId" element={<TabLayout />}>
                <Route
                  path="/home/submissions/:submissionId/extraction"
                  element={
                    <React.Suspense fallback={<>...</>}>
                      <LazyDocumentDetails />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/home/submissions/:submissionId/enrichment"
                  element={
                    <React.Suspense fallback={<>...</>}>
                      <LazyDocumentEnrichment />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/home/submissions/:submissionId/comparison"
                  element={
                    <React.Suspense fallback={<>...</>}>
                      <LazyDocumentComparison />
                    </React.Suspense>
                  }
                />

                <Route
                  path="/home/submissions/:submissionId/validation"
                  element={
                    <React.Suspense fallback={<>...</>}>
                      <LazySubmissionValidation />
                    </React.Suspense>
                  }
                />
              </Route>
            </Route>
          </Route>
        </ViewsRoutes>
      </UserAuthContextProvider>
    </BrowserRouter>
  );
}
export default Routes;
