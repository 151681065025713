import React from 'react';
import './FormattedSubmissionStatus.css';
import { SubmissionStatus } from '../../services/constants/constants';
import { titlecase, removeUnderscore, formattedReviewSeconds } from '../../services/utils/Helper';
import CustomizedTooltip from '../atoms/Tooltip/CustomizedTooltip';

const FormattedSubmissionStatus = ({ status, reviewTime }) => {

    const getSubmissionStatusBg = (status) => {
        let bg = '#E8F0FB';

        switch (status) {
            case SubmissionStatus.Received:
                bg = '#E5E2FC';
                break;
            case SubmissionStatus.Processing:
                bg = '#FDF5E5';
                break;
            case SubmissionStatus.Under_review:
                bg = '#E8F0FB';
                break;
            case SubmissionStatus.Reviewed:
                bg = '#ADD8E6';
                break;
            case SubmissionStatus.Ready_for_comparison:
                bg = '#D4F1E8';
                break;
            default:
                bg = '#E8F0FB';
        }

        return bg;
    }

    const getSubmissionStatusBorder = (status) => {
        let border = '#395DAB';

        switch (status) {
            case SubmissionStatus.Received:
                border = '#9686EA';
                break;
            case SubmissionStatus.Processing:
                border = '#FFAB00';
                break;
            case SubmissionStatus.Under_review:
                border = '#395DAB';
                break;
            case SubmissionStatus.Reviewed:
                border = '#395DAB';
                break;
            case SubmissionStatus.Ready_for_comparison:
                border = '#24B082';
                break;
            default:
                border = '#395DAB';
        }

        return border;
    }

    const getSubmissionStatusFormattedValue = (status) => {
        let formattedStatus = removeUnderscore(titlecase(status));

        if (status?.toLowerCase() === SubmissionStatus.Reviewed.toLowerCase()) {
            if (reviewTime !== 0) {
                const temp = ' - ' + formattedReviewSeconds(reviewTime, true);
                formattedStatus += temp;
            }
        }

        return formattedStatus;
    }

    return (
        <CustomizedTooltip text={getSubmissionStatusFormattedValue(status)}>
            <span style={{ fontWeight: '500', padding: '0.125em 0.5em', color: '#15284B', borderRadius: '0.25rem', border: `1px solid ${getSubmissionStatusBorder(status)}`, background: `${getSubmissionStatusBg(status)}`, display: 'flex', alignItems: 'center', justifyContent: 'center', width: 'fit-content' }}>
                {getSubmissionStatusFormattedValue(status)}</span>
        </CustomizedTooltip>
    )
}

export default React.memo(FormattedSubmissionStatus)
