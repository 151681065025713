import React from 'react'
import { ClassificationTypes } from '../../../../services/constants/constants';
import correctClassification from "../../../../assets/pdf-editor/correct-classification.png";
import cancelClassification from "../../../../assets/pdf-editor/cancel-classification.png";
import './PdfEditor.css'

const EditList = (props) => {
    const { handleClassificationMode, index, handleClassification, width, splitDocument, setSplitDocument, isNewDocument = false } = props
    const [classification, setClassification] = React.useState('');
    const classificationTypeChanged = (e) => {
        if (isNewDocument) {
            const splitDocumentCp = JSON.parse(JSON.stringify(splitDocument));
            splitDocumentCp[index].classification = e.target.value;
            setSplitDocument(splitDocumentCp);
        } else {
            setClassification(e.target.value);
        }
    }

    const showTick = () =>{
        if(isNewDocument){
            if(splitDocument[index].classification !== '')
                return true;
        } else if( classification !== '')
            return true;

    return false;
    }

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '7px' }}>
            <select name="navigateDropdown" id="navigateDropdown"
                className="classification-dropdown" style={{ width: width }} value={isNewDocument ? splitDocument[index].classification :classification}
                onChange={(e) => classificationTypeChanged(e)}>
                <option className="classification-dropdown-option" value='' selected disabled hidden>Select class</option>
                {ClassificationTypes.map((item) => (
                    <option className="classification-dropdown-option" value={item.key}>{item.value}</option>
                ))}
            </select>
            {showTick() ? <span onClick={(e) => handleClassification(e, index, classification, isNewDocument)}><img src={correctClassification} alt="" height={'15px'} /> </span> : <span></span>}
            <span onClick={() => handleClassificationMode(index, 'classification', isNewDocument)}><img src={cancelClassification} alt="" height={'15px'} /></span>
        </div>
    )
}
export default EditList;
