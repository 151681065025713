import React from 'react';
import './SubmissionSummary.css';

const SubmissionSummary = ({ submissionsCount, currentPage, pageSize }) => {

    const getInitialItemCount = () => {
        return currentPage * pageSize - (pageSize - 1);
    };

    const getFinalItemCount = () => {
        return currentPage * pageSize > submissionsCount
            ? submissionsCount
            : currentPage * pageSize;
    };

    return (
        <div className='dataTables_info' id='datatable-table_info'>
            {submissionsCount === 0 ?
                <p>Showing <strong>{submissionsCount}</strong> entries</p> :
                <p>Showing {getInitialItemCount()} to {getFinalItemCount()} of {submissionsCount} entries</p>
            }
        </div>
    )
}

export default React.memo(SubmissionSummary)
