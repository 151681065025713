import React from 'react';
import Dialog from '@mui/material/Dialog';
import PdfEditor from '../pdf-editor/PdfEditor';
import './SubmissionAttachmentActions.css';
import { SubClassificationTypes, AttachmentStatus, ReadOnlyMode, OtherType, MergedType } from '../../../../services/constants/constants';
import { PolicyDocumentType, BinderQuote, ExposureType } from '../../../../services/constants/constants';
import { toast } from 'react-toastify';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useNavigate } from 'react-router-dom';
import ButtonOutlined from '../../../button/ButtonOutlined';
import CustomizedTooltip from '../../../atoms/Tooltip/CustomizedTooltip';
import useAxios from '../../../../hooks/useAxios';
import pdfIcon from '../../../../assets/dashboard/pdfIcon.svg';

const SubmissionAttachmentActions = React.forwardRef((props, ref) => {

    const axiosPrivate = useAxios();
    const { submissionId, documentId, attachment, editMode, old_output_json, parentDocumentId } = props;
    const { handleClassificationEdit, handleClassificationEditCancel, handleClassificationApproval } = props;
    const { handleClassificationUpdate, handleExtractionInProgress, fetchSubmissions, documents } = props;

    const [approveLoading, setApproveLoading] = React.useState(false);
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const [openPdfEditor, setOpenPdfEditor] = React.useState(false);
    const [fullScreen, setFullScreen] = React.useState(false);

    const navigate = useNavigate();

    React.useImperativeHandle(ref, () => ({
        approveDocument() {
            approveDocument(true);
        },
    }));

    const isMergedClassification = () => {
        return attachment?.output_json?.type?.toUpperCase() === 'MERGED';
    }

    const hasSubClassification = () => {
        return SubClassificationTypes.some(
            (x) => x.key.toUpperCase() === attachment?.output_json?.type?.toUpperCase()
        );
    }

    const isClassificationUnknown = () => {
        return attachment?.output_json?.type?.toUpperCase() === 'UNKNOWN';
    }

    const canExtract = () => {
        return (
            !isClassificationUnknown() && attachment?.output_json?.approved &&
            (attachment?.status === AttachmentStatus.Processed ||
                attachment?.status === AttachmentStatus.Reviewed)
        );
    }

    const canEdit = () => {
        return (
            (!attachment?.output_json?.approved || isClassificationUnknown()) &&
            (attachment?.status === AttachmentStatus.Classified ||
                attachment?.status === AttachmentStatus.Extracting ||
                attachment?.status === AttachmentStatus.Processed ||
                attachment?.status === AttachmentStatus.Reviewed)
        );
    }

    const canApprove = () => {
        if (hasSubClassification()) {
            if (!attachment?.output_json?.sub_type) {
                return false;
            }
        }

        return (
            !attachment?.output_json?.approved && !isClassificationUnknown() &&
            (attachment?.status === AttachmentStatus.Classified ||
                attachment?.status === AttachmentStatus.Extracting ||
                attachment?.status === AttachmentStatus.Processed ||
                attachment?.status === AttachmentStatus.Reviewed)
        );
    }

    const approveDocument = (hideToast = false) => {
        if (attachment.output_json.approved || attachment.status !== AttachmentStatus.Classified) return;

        const outputJson = { ...attachment.output_json, approved: true };
        setApproveLoading(true);


        axiosPrivate.put(`submissions/${submissionId}/documents/${documentId}/classification`, outputJson)
            .then(res => {
                setApproveLoading(false);
                handleClassificationApproval();
                const classificationType = attachment?.output_json?.type?.toUpperCase();
                if (
                    classificationType !== OtherType.toUpperCase() &&
                    classificationType !== MergedType.toUpperCase()
                ) {
                    handleExtractionInProgress();
                }

                if (!hideToast) {
                    toast.success('Document confirmed successfully');
                }
            }, err => {
                setApproveLoading(false);
                if (!hideToast) {
                    toast.error('Failed to confirm the document');
                }
                console.log(err);
            });
    }

    const viewExtraction = () => {
        const route = `/home/submissions/${submissionId}/extraction?docs=${documentId}`;
        navigate(route);
    }

    const onEditClassification = () => {
        handleClassificationEdit();
    }

    const onClassificationCancel = () => {
        handleClassificationEditCancel();
    }

    const updateClassification = () => {
        const outputJson = { ...attachment.output_json, hitl_confirmed: true };

        if (outputJson.carrier_name?.toUpperCase() === old_output_json.carrier_name?.toUpperCase() &&
            outputJson.type?.toUpperCase() === old_output_json.type?.toUpperCase()) {
            handleClassificationEditCancel()
        }
        else {
            setConfirmLoading(true);
            axiosPrivate.put(`submissions/${submissionId}/documents/${documentId}/classification`, outputJson)
                .then(res => {
                    setConfirmLoading(false);
                    handleClassificationUpdate();
                    toast.success('Document updated successfully');
                    setTimeout(() => {
                        fetchSubmissions(null, "silent");
                    }, 5000);
                }, err => {
                    setConfirmLoading(false);
                    toast.error('Failed to update the document');
                    console.log(err);
                });
        }
    }

    const handleOpen = () => {
        setOpenPdfEditor(true);
    };

    const handleClose = () => {
        setOpenPdfEditor(false);
        setFullScreen(false);
    };

    return (
        <React.Fragment>
            {!isMergedClassification() &&
                <React.Fragment>
                    <React.Fragment>
                        {!editMode ?
                            <React.Fragment>
                                {(canExtract() || canEdit() || canApprove()) &&
                                    <React.Fragment>
                                        {ReadOnlyMode === false && canEdit() && !approveLoading &&
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                <CustomizedTooltip text="Change the classification" position="top">
                                                    <span>
                                                        <ButtonOutlined className='btn btn-outline-danger btn-sm act-ptooltip'
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => onEditClassification()}
                                                            text="Edit"
                                                        />
                                                    </span>
                                                </CustomizedTooltip>
                                                <CustomizedTooltip text="Confirm the classification" position="top">
                                                    <span>
                                                        <ButtonOutlined
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => approveDocument(true)}
                                                            text="Confirm"
                                                        />
                                                    </span>
                                                </CustomizedTooltip>
                                                {
                                                    attachment.is_splitted_document &&
                                                    <CustomizedTooltip text="PDF Editor" position="top">
                                                        <span style={{ cursor: 'pointer', border: '1px solid #AEB3BC', padding: '0.15rem', borderRadius: '0.25rem' }}>
                                                            <img src={pdfIcon} alt="pdf" height="24px" onClick={() => setOpenPdfEditor(true)} />
                                                        </span>
                                                    </CustomizedTooltip>
                                                }
                                            </div>
                                        }
                                        {attachment?.output_json?.approved ?
                                            <React.Fragment>
                                                <ButtonOutlined style={{ cursor: 'pointer' }} text="View Extraction" onClick={() => viewExtraction()} disabled={!canExtract()} borderColor="#AEB3BC" textColor="#3C4C68" />
                                            </React.Fragment> :
                                            <React.Fragment>
                                                {approveLoading ?
                                                    <div className='progress-loader'>
                                                        <ProgressSpinner style={{ width: '20px', height: '20px' }} strokeWidth='8' fill='var(--surface-ground)' animationDuration='.5s' />
                                                    </div>
                                                    :
                                                    <React.Fragment></React.Fragment>
                                                }
                                            </React.Fragment>
                                        }
                                    </React.Fragment>
                                }
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <CustomizedTooltip text="Cancel the classification update" position="top">
                                        <span style={{ cursor: 'pointer', marginRight: '1.5rem' }}>
                                            <ButtonOutlined className='btn btn-sm btn-outline-danger act-ptooltip'
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => onClassificationCancel()}
                                                text="Cancel"
                                            />
                                        </span>
                                    </CustomizedTooltip>
                                    {confirmLoading ?
                                        <div className='progress-loader'>
                                            <ProgressSpinner style={{ width: '20px', height: '20px' }} strokeWidth='8' fill='var(--surface-ground)' animationDuration='.5s' />
                                        </div>
                                        :
                                        <React.Fragment>
                                            <CustomizedTooltip text="Save the classification" position="top">
                                                <span>
                                                    <ButtonOutlined className='btn btn-sm btn-outline-success ml-2 act-ptooltip'
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => updateClassification()}
                                                        text="Confirm"
                                                    />
                                                </span>
                                            </CustomizedTooltip>
                                        </React.Fragment>
                                    }
                                </div>

                            </React.Fragment>
                        }
                    </React.Fragment>
                </React.Fragment>
            }
            <Dialog
                onClose={handleClose}
                open={openPdfEditor}
                sx={{
                    zIndex: '100000',
                }}
                fullScreen={fullScreen}
                fullWidth
                maxWidth="lg"
                disableEscapeKeyDown
            >
                <PdfEditor setFullScreen={setFullScreen} fullScreen={fullScreen} handleClose={handleClose}
                    submissionId={submissionId}
                    documents={documents}
                    parentDocumentId={parentDocumentId}
                    fetchSubmissions={fetchSubmissions}
                />
            </Dialog>
        </React.Fragment>
    )
})

export default React.memo(SubmissionAttachmentActions)
