import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { CloseOutlined, MenuOutlined, ZoomInOutlined } from '@ant-design/icons';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import documentIcon from '../../../../assets/dashboard/documentIcon.svg';
import addIcon from '../../../../assets/dashboard/addIcon.svg';
import arrowRight from '../../../../assets/dashboard/arrowRight.svg'
import plusIcon from '../../../../assets/dashboard/plusIcon.svg';
import splitIcon from '../../../../assets/dashboard/splitIcon.svg';
import ButtonFilled from '../../../button/ButtonFilled';
import EditList from './EditList';
import getThumbnails from '../../../../services/utils/Thumbnail';
import useAxios from '../../../../hooks/useAxios';
import { toast } from 'react-toastify';
import ControlledTooltip from "../../../atoms/Tooltip/ControlledTooltip";
import { v4 as uuidv4 } from 'uuid';
import './PdfEditor.css'
import axios from "axios";
import Loader from "../../../loader/Loader";
import { Backdrop } from '@mui/material';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  // ...theme.mixins.toolbar,
  justifyContent: 'space-between',
  minHeight: '40px',
  position: 'sticky',
  top: 0,
  zIndex: 1,
  backgroundColor: 'white',
}));

const SideDrawer = (props) => {
  const { documents, submissionId, fullScreen, parentDocumentId, handleClose, fetchSubmissions } = props;
  const [isOpenDrawer, setIsOpenDrawer] = React.useState(true);
  const [expandThumbnail, setExpandThumbnail] = React.useState(false);
  const [expandThumbnailSrc, setExpandThumbnailSrc] = React.useState('');
  const [attachment, setAttachment] = React.useState(documents);
  const [activeIndex, setActiveIndex] = React.useState(0);

  const [showTempPage, setShowTempPage] = React.useState(false);
  const [isCreateNewDoc, setIsCreateNewDoc] = React.useState(false);
  const [openCreateNewDoc, setOpenCreateNewDoc] = React.useState(false);
  const [newPageIndex, setNewPageIndex] = React.useState(-1);
  const [splitCount, setSplitCount] = React.useState(0);
  // Add isHovered state for all list items
  const [isHovered, setIsHovered] = React.useState(-1);
  const [pageMove, setPageMove] = React.useState('');
  const [totalDocumentCount, setTotalDocumentCount] = React.useState(0);

  // Store previously loaded thumbnail source URLs
  const [thumbnailCache, setThumbnailCache] = React.useState([]);
  const [splitDocument, setSplitDocument] = React.useState([]);
  const [leftBorderIndex, setLeftBorderIndex] = React.useState(-1);
  const [loading, setLoading] = React.useState(false);
  const [restrictHoverIndex, setRestrictHoverIndex] = React.useState(-1);
  const [dragItem, setDragItem] = React.useState(null);
  const [dragListItemIndex, setDragListItemIndex] = React.useState(null);
  const [feedbackCanvasImg, setFeedbackCanvasImg] = React.useState(false);
  const [feedbackCanvasImgPath, setFeedbackCanvasImgPath] = React.useState(null);
  const [saveDocsLoading, setSaveDocsLoading] = React.useState(false);
  const canvasRef = React.useRef(null);

  const axiosPrivate = useAxios();

  const handleDrawerOpen = () => {
    setIsOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setIsOpenDrawer(false);
  };

  const handleExpandThumbnail = (img) => {
    setExpandThumbnail(true);
    setExpandThumbnailSrc(img)
  }
  const createDragImageWithText = (imgSrc, count) => {
    return new Promise((resolve, reject) => {
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      const image = new Image();
      image.onload = function () {
        canvas.width = image.width;
        canvas.height = image.height;

        context.fillStyle = "rgba(21, 40, 75, 0.7)";
        context.fillRect(0, 0, canvas.width, canvas.height);
        context.drawImage(image, 0, 0);

        const fontSize = 50;
        context.font = `bold ${fontSize}px Arial`;
        context.textAlign = "center";

        const x = canvas.width / 2;
        const y = canvas.height / 2;

        // Background
        context.fillStyle = "blue";
        context.fillRect(x - fontSize, y - fontSize, fontSize * 2, fontSize * 2);

        // Text
        context.fillStyle = "white";
        context.fillText(count, x, y + fontSize / 2);

        const finalImage = new Image();
        finalImage.onload = function () {
          const dataURL = canvas.toDataURL();
          resolve(dataURL);
        };
        finalImage.onerror = function () {
          reject(new Error("Failed to generate the final image."));
        };
        finalImage.src = canvas.toDataURL();
      };

      image.onerror = function () {
        reject(new Error("Failed to load the image."));
      };
      image.src = imgSrc;
    });
  };

  const handleDrag = (event) => {
    // Update the position of the custom drag image
    const dragImage = document.getElementById('custom-drag-image');
    if (event?.clientX && event?.clientY && dragImage?.style) {
      dragImage.style.left = `${event.clientX}px`;
      dragImage.style.top = `${event.clientY}px`;
    }
  };

  const handleDragEnd = () => {
    // Clear the drag item
    setDragItem(null);
    setFeedbackCanvasImg(false);
    setDragListItemIndex(null);
  };

  const handleDragStart = async (event, index, state = '') => {
    if (state === 'page_move') {
      setPageMove('page_move');
      setRestrictHoverIndex(activeIndex);
    } else {
      setRestrictHoverIndex(index);
      setPageMove('');
    }
    event.dataTransfer.setData('text/plain', index.toString());
    const checkedthumbnailCacheCp = JSON.parse(JSON.stringify(thumbnailCache));

    if (state === 'list-item-drag') {
      setDragListItemIndex(index);
      const draggingListItem = checkedthumbnailCacheCp.find(x => x.documentId === attachment[index].document_id);
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');

      // Draw your canvas content
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.lineWidth = 5;
      ctx.setLineDash([2, 2]);
      ctx.strokeRect(0, 0, canvas.width, canvas.height);
      ctx.imageSmoothingEnabled = false;

      // Store the drag item
      setDragItem({
        documentName: draggingListItem.documentName,
        classificationType: draggingListItem.classificationType
      });
      event.dataTransfer.setDragImage(new Image(), 0, 0);

      // Set the drag image and its offset
      // event.dataTransfer.setDragImage(dragImage, 0, 0);

    } else {
      const checkedthumbnail = checkedthumbnailCacheCp.find(x => x.documentId === attachment[activeIndex].document_id)?.pages.filter(y => y.checked);
      const getListItemImg = checkedthumbnailCacheCp.find(x => x.documentId === attachment[activeIndex].document_id)?.pages.filter(y => y);

      const imgSrc = checkedthumbnail.length === 0 ? getListItemImg[0].src : checkedthumbnail[0].src;

      setFeedbackCanvasImg(true);
      await event.dataTransfer.setDragImage(new Image(), 0, 0);

      // setFeedbackCanvasImg(imgSrc);
      await createDragImageWithText(imgSrc, checkedthumbnail.length === 0 ? 1 : checkedthumbnail.length)
        .then(finalImageDataURL => {
          setFeedbackCanvasImgPath(finalImageDataURL);
        })
        .catch(error => {
          console.error(error);
        });

  }

  };

  const handleDragOver = (event, index, borderIndex = -4) => {
    event.preventDefault();
    handleDrag(event);
    if (index === -1 && pageMove !== '') {
      setIsCreateNewDoc(true);
      setIsHovered(-1);
      setLeftBorderIndex(-1);
    }
    else if (borderIndex !== -4) {
      setLeftBorderIndex(borderIndex);
    }
    else {
      setLeftBorderIndex(-1);
      // Update the isHovered state for the specific list item
      if (index !== -2 && restrictHoverIndex !== index) {
        setIsHovered(index);
      } else {
        setIsHovered(-1);
      }
    }
  };

  const handleDragLeave = (event, index) => {
    event.preventDefault();
    if (index === -1) {
      setIsCreateNewDoc(false);
      setIsHovered(-1);
    } else if (index === -2) {
      setIsHovered(-1);
    }
  };

  const handleDrop = (event, newIndex, obj, status = -1) => {
    event.preventDefault();
    setShowTempPage(true);
    const data = event.dataTransfer.getData('text/plain');
    const oldIndex = parseInt(data);

    // re-arrangement of document
    if (status === 1 && pageMove !== '') {
      const data = event.dataTransfer.getData('text/plain');
      const oldIndex = parseInt(data);
      // Create a copy of the thumbnails array
      const checkedthumbnailCacheCp = JSON.parse(JSON.stringify(thumbnailCache));
      const pages = checkedthumbnailCacheCp.find(x => x.documentId === obj.documentId).pages

      // Remove the moved thumbnail from the old position
      const movedThumbnail = pages.splice(oldIndex, 1)[0];

      // Insert the moved thumbnail at the new position
      pages.splice(newIndex, 0, movedThumbnail);

      checkedthumbnailCacheCp.find(x => x.documentId === obj.documentId).pages = pages;
      checkedthumbnailCacheCp.find(x => x.documentId === obj.documentId).isModify = true;
      setThumbnailCache(checkedthumbnailCacheCp);

    }
    // Create new document
    else if (status === 3) {
      setNewPageIndex(oldIndex);
      setOpenCreateNewDoc(true);
      setIsCreateNewDoc(false);

      let highestPartNumber = -1;
      let documentName = '';
      attachment.forEach((document) => {

        const partNumber = parseInt(document.document_name.split("_part_")[1]);
        if (partNumber > highestPartNumber) {
          highestPartNumber = partNumber;
          documentName = document.document_name.split("_part_")[0];
        }
      });
      const documents = [];
      documents.push({
        isEdit: true,
        documentName: `${documentName}_part_${highestPartNumber + 1}`,
        classification: '',
      });
      setSplitDocument(documents);
    }
    // move pages from one document to another document
    else if (obj.document_id !== attachment[activeIndex].document_id && pageMove !== '') {
      setIsHovered(-1);

      const documentId = attachment[activeIndex].document_id;
      const checkedthumbnailCacheCp = JSON.parse(JSON.stringify(thumbnailCache));
      const attachmentCp = JSON.parse(JSON.stringify(attachment));
      const filterPages = checkedthumbnailCacheCp.find(x => x.documentId === documentId)?.pages

      if (filterPages.filter(y => y.checked).length > 0) {
        // push checked thumbnail in side list items
        const checkedthumbnail = filterPages.filter(y => y.checked);
        // delete checked thumbnail in side list items
        const checkedthumbnailRemove = filterPages.filter(y => !y.checked);

        // show tooltip
        attachmentCp.find(x => x.document_id === obj.document_id).showTooltip = true;

        checkedthumbnail.forEach((thumbnail) => {
          thumbnail.checked = false;
          checkedthumbnailCacheCp.find(x => x.documentId === obj.document_id).pages.push(thumbnail);
        });
        checkedthumbnailCacheCp.find(x => x.documentId === obj.document_id).isModify = true;

        checkedthumbnailCacheCp.find(x => x.documentId === documentId).pages = checkedthumbnailRemove;
        checkedthumbnailCacheCp.find(x => x.documentId === documentId).isModify = true;
        setThumbnailCache(checkedthumbnailCacheCp);
      }
      else {
        const pages = checkedthumbnailCacheCp.find(x => x.documentId === documentId)?.pages;
        checkedthumbnailCacheCp.find(x => x.documentId === documentId).isModify = true;
        const checkedthumbnail = pages?.find(y => y.page === pages[oldIndex].page);
        const checkedthumbnailRemove = pages.filter(y => y.page !== pages[oldIndex].page);

        checkedthumbnailCacheCp.find(x => x.documentId === obj.document_id).pages.push(checkedthumbnail);
        checkedthumbnailCacheCp.find(x => x.documentId === obj.document_id).isModify = true;

        // show tooltip
        attachmentCp.find(x => x.document_id === obj.document_id).showTooltip = true;

        checkedthumbnailCacheCp.find(x => x.documentId === documentId).pages = checkedthumbnailRemove;
        setThumbnailCache(checkedthumbnailCacheCp);
      }

      setAttachment(attachmentCp);
      setActiveIndex(attachment.findIndex(x => x.document_id === obj.document_id));
      setSplitCount(attachmentCp.findIndex(x => x.document_id === obj.document_id));

    }

    // merge one document to another
    else if (obj.document_id !== attachment[oldIndex].document_id && pageMove === '') {
      setIsHovered(-1);
      const checkedthumbnailCacheCp = JSON.parse(JSON.stringify(thumbnailCache));
      const documentId = attachment[oldIndex].document_id;

      const movingPages = checkedthumbnailCacheCp.find(x => x.documentId === documentId)?.pages;
      const inMovingPages = checkedthumbnailCacheCp.find(x => x.documentId === obj.document_id)?.pages;

      movingPages.forEach((obj) => {
        inMovingPages.push(obj);
      });

      checkedthumbnailCacheCp.find(x => x.documentId === obj.document_id).pages = inMovingPages;
      checkedthumbnailCacheCp.find(x => x.documentId === obj.document_id).isModify = true;
      checkedthumbnailCacheCp.find(x => x.documentId === documentId).pages = [];
      checkedthumbnailCacheCp.find(x => x.documentId === documentId).isModify = true;
      setThumbnailCache(checkedthumbnailCacheCp);

      const attachmentCp = JSON.parse(JSON.stringify(attachment));
      attachmentCp.find(x => x.document_id === obj.document_id).showTooltip = true;

      attachmentCp.find((item) => item.document_id === documentId).is_deleted = true;
      setSplitCount(attachmentCp.findIndex(x => x.document_id === obj.document_id));
      setActiveIndex(attachmentCp.findIndex(x => x.document_id === obj.document_id));
      setAttachment(attachmentCp);
    }

    setPageMove('');
    setLeftBorderIndex(-1);
    setIsHovered(-1);
    // Update the page numbers after 1 second
    setTimeout(() => {
      setShowTempPage(false);
    }, 1000);
    setRestrictHoverIndex(-1);
    handleDragEnd();
  };

  const handleToggleThumbnail = (docId, index) => {
    const thumbnailCacheCp = JSON.parse(JSON.stringify(thumbnailCache));
    thumbnailCacheCp.find(x => x.documentId === docId).pages.forEach((thumbnail, i) => {
      if (i === index) {
        thumbnail.checked = !thumbnail.checked
      }
    });

    setThumbnailCache(thumbnailCacheCp);
  };

  const handleClassificationMode = (index, status, isNewDocument = false) => {
    if (status === 'classification' && isNewDocument) {
      const updatedDocument = splitDocument.filter(x => x !== splitDocument[index])
      setSplitDocument(updatedDocument)
    }

    else if (status === 'classification') {
      const documentsCp = JSON.parse(JSON.stringify(attachment));
      if (!documentsCp[index].isEdit) {
        documentsCp[index].isEdit = true;
      }
      else {
        documentsCp[index].isEdit = false;
      }
      setAttachment(documentsCp);
    } else if (status === 'document_name') {
      const documentsCp = JSON.parse(JSON.stringify(attachment));
      if (!documentsCp[index].isNameEdit) {
        documentsCp[index].isNameEdit = true;
        documentsCp[index].document_temp_name = documentsCp[index].document_name;
      }
      else {
        documentsCp[index].isNameEdit = false;
      }
      setAttachment(documentsCp);
    }
  }

  const handleListItemClick = (index) => {
    setActiveIndex(index);
    if (splitDocument.length === 0) {
      setSplitCount(index);
    }
    // fetchDocumentPresignedUrl(index);
  }

  const fetchDocumentPresignedUrl = async () => {
    setLoading(true);
    let thumbnailCacheCp = JSON.parse(JSON.stringify(thumbnailCache));
    thumbnailCacheCp = [];
    const uploadReq = [];
    for (let i = 0; i < attachment.length; i++) {
      uploadReq.push(axiosPrivate.get(`submissions/${submissionId}/documents/${attachment[i].document_id}/presignedurl?type=document`));
    }
  
    try {
      const responses = await Promise.all(uploadReq);
  
      const thumbnailRequests = responses.map((response, index) => {
        const presigned_url = response.data.presignedurl;
        return getThumbnails(presigned_url).then((thumbnails) => {
          const documentId = response.data.document_id;
          let pages = [];
  
          if (attachment[index].parent_page_numbers !== null) {
            pages = thumbnails.map((thumbnail, idx) => {
              return { ...thumbnail, parentPageNumber: JSON.parse(attachment[index].parent_page_numbers)[idx] };
            });
          }
          // console.log( response.data.document_name, attachment[index].parent_page_numbers);
          return {
            classificationType: attachment[index].output_json.type,
            subClassificationType: attachment[index].output_json.sub_type,
            documentName: attachment[index].document_name,
            documentId: documentId,
            pages: pages.length > 0 ? pages : thumbnails,
          };
        });
      });
  
      const thumbnailResults = await Promise.all(thumbnailRequests);
      thumbnailCacheCp = thumbnailResults;
      setThumbnailCache(thumbnailCacheCp);
      setLoading(false);
    } catch (errors) {
      // react on errors.
      toast.error("Application upload failed!");
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchDocumentPresignedUrl();
  }, [])

  React.useEffect(() => {
    getListItemsLength();
    disableSaveBtn();
  }, [thumbnailCache])

  // React.useEffect(() => {
  //   console.log('data = ', thumbnailCache);
  // }, [thumbnailCache])

  const handleClassification = (event, index, classification, isNewDocument = false) => {
    const attachmentCp = JSON.parse(JSON.stringify(attachment));
    if (!isNewDocument) {
      if (attachmentCp[index]?.output_json?.type?.toLowerCase() === classification?.toLowerCase()) {
        attachmentCp[index].isEdit = false;
        setAttachment(attachmentCp);
      } else {
        attachmentCp[index].isEdit = false;
        attachmentCp[index].output_json.type = classification;

        const checkedthumbnailCacheCp = JSON.parse(JSON.stringify(thumbnailCache));
        checkedthumbnailCacheCp.find((obj) => obj.documentId === attachmentCp[index].document_id).classificationType = classification
        checkedthumbnailCacheCp.find((obj) => obj.documentId === attachmentCp[index].document_id).isModify = true;
        setAttachment(attachmentCp);
        setThumbnailCache(checkedthumbnailCacheCp);

      }
    } else {
      const newDocumentId = uuidv4();
      const obj = {
        document_id: newDocumentId,
        document_name: splitDocument[index].documentName + '.pdf',
        status: "classified",
        classification: splitDocument[index].classification,
        is_splitted_document: false,
        parent_document_id: null,
        output_json: {
          sub_type: "",
          confidence: 1,
          approved: true,
          hitl_confirmed: true,
          type: splitDocument[index].classification,
        }
      }
      const attachmentCp = JSON.parse(JSON.stringify(attachment));
      attachmentCp.push(obj);
      setAttachment(attachmentCp);

      setIsCreateNewDoc(false);
      setOpenCreateNewDoc(false);
      const documentId = attachment[splitCount].document_id;
      const checkedthumbnailCacheCp = JSON.parse(JSON.stringify(thumbnailCache));

      const filterPages = checkedthumbnailCacheCp.find(x => x.documentId === documentId)?.pages;
      if (filterPages.filter(y => y.checked).length > 0) {

        if (openCreateNewDoc) {
          // push checked thumbnail in side list items
          const checkedthumbnail = filterPages.filter(y => y.checked);
          // delete checked thumbnail in side list items
          const checkedthumbnailRemove = filterPages.filter(y => !y.checked);
          const newPages = [];

          checkedthumbnail.forEach((thumbnail) => {
            thumbnail.checked = false;
            newPages.push(thumbnail);
          });

          checkedthumbnailCacheCp.find(x => x.documentId === documentId).pages = checkedthumbnailRemove;
          checkedthumbnailCacheCp.find(x => x.documentId === documentId).isModify = true;
          checkedthumbnailCacheCp.push({
            documentId: newDocumentId,
            pages: newPages,
            classificationType: splitDocument[index].classification,
            documentName: splitDocument[index].documentName + '.pdf',
            isNewDocument: true
          })

          setActiveIndex(attachmentCp.findIndex(x => x.document_id === newDocumentId));
          setSplitCount(attachmentCp.findIndex(x => x.document_id === newDocumentId));
        }
        else {
          let count = 0
          const checkedthumbnailIndex = filterPages.findIndex(item => {
            if (item.checked && count === index) {
              return item;
            }
            else if (item.checked) {
              count += 1;
            }
            return false;
          });

          const checkedthumbnail = filterPages[checkedthumbnailIndex];
          const updatedPages = filterPages.filter(x => x !== filterPages[checkedthumbnailIndex]);
          checkedthumbnailCacheCp.find(x => x.documentId === documentId).pages = updatedPages;
          checkedthumbnailCacheCp.find(x => x.documentId === documentId).isModify = true;

          checkedthumbnail.checked = false;
          checkedthumbnailCacheCp.push({
            documentId: newDocumentId,
            pages: [checkedthumbnail],
            classificationType: splitDocument[index].classification,
            documentName: splitDocument[index].documentName + '.pdf',
            isNewDocument: true
          })
        }

        const updatedDocument = splitDocument.filter(x => x !== splitDocument[index])
        setSplitDocument(updatedDocument);
        setThumbnailCache(checkedthumbnailCacheCp);
      }
      else {
        const pages = checkedthumbnailCacheCp.find(x => x.documentId === documentId)?.pages;
        // const checkedthumbnail = pages.filter(y => y.page === pages[newPageIndex].page);
        // const checkedthumbnailRemove = pages.filter(y => y.page !== pages[newPageIndex].page);
        const checkedthumbnailIndex = pages.findIndex(y => y.page === pages[newPageIndex].page);
        const checkedthumbnail = pages[checkedthumbnailIndex]
        pages.splice(checkedthumbnailIndex, 1);
        // const checkedthumbnailRemove = pages.filter(y => y.page !== pages[newPageIndex].page);

        checkedthumbnailCacheCp.push({
          documentId: newDocumentId,
          pages: [checkedthumbnail],
          classificationType: splitDocument[index].classification,
          documentName: splitDocument[index].documentName + '.pdf',
          isNewDocument: true
        })
        checkedthumbnailCacheCp.find(x => x.documentId === documentId).pages = pages;
        checkedthumbnailCacheCp.find(x => x.documentId === documentId).isModify = true;
        const updatedDocument = splitDocument.filter(x => x !== splitDocument[index])
        setSplitDocument(updatedDocument);
        setThumbnailCache(checkedthumbnailCacheCp);
        setActiveIndex(attachmentCp.findIndex(x => x.document_id === newDocumentId));
        setSplitCount(attachmentCp.findIndex(x => x.document_id === newDocumentId));
      }
    }
  }

  const getListItemsLength = () => {
    if (thumbnailCache.filter(item => item?.pages.length > 0).length === 0)
      setTotalDocumentCount(attachment?.length);
    else
      setTotalDocumentCount(thumbnailCache.filter(item => item?.pages.length > 0).length);
  }

  const handleChange = (e, index, isNewDocument = false) => {
    const attachmentCp = JSON.parse(JSON.stringify(attachment));
    if (isNewDocument) {
      const splitDocumentCp = JSON.parse(JSON.stringify(splitDocument));
      splitDocumentCp[index].documentName = e.target.value;
      setSplitDocument(splitDocumentCp);
    }
    else if (e.key === 'Escape' && e._reactName === "onKeyUp") {
      attachmentCp[index].document_temp_name = '';
      attachmentCp[index].isNameEdit = false;
      setAttachment(attachmentCp);
    }
    else if (e.key === 'Enter' && e._reactName === "onKeyUp") {
      attachmentCp[index].document_name = attachmentCp[index].document_temp_name;
      attachmentCp[index].isNameEdit = false;

      const checkedthumbnailCacheCp = JSON.parse(JSON.stringify(thumbnailCache));
      checkedthumbnailCacheCp.find((obj) => obj.documentId === attachmentCp[index].document_id).documentName = attachmentCp[index].document_name;
      checkedthumbnailCacheCp.find((obj) => obj.documentId === attachmentCp[index].document_id).isModify = true;
      setThumbnailCache(checkedthumbnailCacheCp);

      setAttachment(attachmentCp);
    } else if (e._reactName === 'onChange') {
      const firstName = e.target.value;
      const secondName = attachmentCp[index].document_name.split('.')[1];
      attachmentCp[index].document_temp_name = firstName + '.' + secondName;
      setAttachment(attachmentCp);
    }
  }

  const setSplitDocumentLength = () => {
    const len = thumbnailCache.find(x => x.documentId === attachment[activeIndex].document_id).pages.filter(y => y.checked).length

    let highestPartNumber = -1;
    let documentName = '';
    attachment.forEach((document) => {

      const partNumber = parseInt(document.document_name.split("_part_")[1]);
      if (partNumber > highestPartNumber) {
        highestPartNumber = partNumber;
        documentName = document.document_name.split("_part_")[0];
      }
    });
    const documents = [];
    for (let i = 0; i < len; i++) {
      let newPartNumber = highestPartNumber + i + 1;
      documents.push({
        isEdit: true,
        documentName: `${documentName}_part_${newPartNumber}`,
        classification: '',
      })
    }

    setSplitDocument(documents);
  }

  const handleCloseTooltip = (obj) => {
    const attachmentCp = JSON.parse(JSON.stringify(attachment));
    attachmentCp.find(x => x.document_id === obj.document_id).showTooltip = false;
    setAttachment(attachmentCp);
  }

  const handleExistingAndNewDocumentChange = async () => {
    const changesPayload = [];
    const newDocumentPayload = [];
    setSaveDocsLoading(true);
    const thumbnailCacheCp = JSON.parse(JSON.stringify(thumbnailCache));
    const patchPromises = [];
    const putPromises = []; 
    for (const item of thumbnailCacheCp) {
      if (item.isModify) {
        changesPayload.push({
          document_id: item.documentId,
          parent_page_numbers: item.pages.map((x) => x.parentPageNumber),
        });
  
        const payload = {
          document_name: item.documentName,
          document_id: item.documentId,
          parent_page_numbers: item.pages.map((x) => x.parentPageNumber),
          classification: item.classificationType,
          sub_classification: item.subClassificationType,
        };
        item.isModify = false;
  
        const patchPromise = axiosPrivate.patch(`/submissions/${submissionId}/documents/${item.documentId}/pdf-operations`, payload);
        patchPromises.push(patchPromise);
      }
  
      if (item.isNewDocument && item.pages?.length > 0) {
        newDocumentPayload.push({
          document_name: item.documentName,
          classification: item.classificationType,
          sub_classification: "",
          parent_page_numbers: item.pages.map((x) => x.parentPageNumber),
        });
  
        const payload = {
          document_name: item.documentName,
          classification: item.classificationType,
          sub_classification: "",
          parent_page_numbers: item.pages.map((x) => x.parentPageNumber),
        };
        item.isNewDocument = false;
  
        const putPromise = axiosPrivate.put(`/submissions/${submissionId}/documents/${parentDocumentId}/pdf-operations`, payload);
        putPromises.push(putPromise);
      }
    }
  
    try {
      await Promise.all([...patchPromises, ...putPromises]);
      setSaveDocsLoading(false);
      handleClose();
      setTimeout(() => {
        fetchSubmissions(null);
    }, 2000);
      setThumbnailCache(thumbnailCacheCp);
      console.log('Payload = ', thumbnailCacheCp);
    } catch (err) {
      handleClose();
      console.log(err);
      setSaveDocsLoading(false);
    }
  };
  
  const handleDraggableImagLeave = () => {
    setLeftBorderIndex(-1);
    setFeedbackCanvasImg(false);
  }

  const disableSaveBtn = () => {
    for (const item of thumbnailCache) {
      if (item.isModify || item.isNewDocument) {
        return false;
      }
    }
    return true;
  }
  return (
    <>
     { saveDocsLoading &&
            <Backdrop open={saveDocsLoading}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '85vh', position: 'absolute', top: 0, left: '50%' }}>
              <Loader sideDrawer />
            </div>
            </Backdrop>
            }

    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* <AppBar position="fixed" open={isOpenDrawer}> */}
      {/* <Toolbar> */}
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
        sx={{
          mr: 2, ...(isOpenDrawer ? { display: 'none' } : {
            display: 'flex !important',
            alignItems: 'flex-start !important',
            marginTop: '0.75rem',
            marginLeft: '0.75rem',
          })
        }}
      >
        <MenuOutlined />
      </IconButton>
      {/* </Toolbar> */}
      {/* </AppBar> */}
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
          '& .MuiDrawer-root': {
            position: 'absolute'
          },
          '& .MuiPaper-root': {
            position: 'absolute',
            top: 'auto',
            height: '-webkit-fill-available',
          },
        }}
        variant="persistent"
        anchor="left"
        open={isOpenDrawer}
      >
        <DrawerHeader >
          <div>
            <img src={documentIcon} alt="document" style={{ paddingLeft: '0.5rem', paddingRight: '1rem' }} />
            <span className='h3Medium' style={{ color: '#15284B', fontWeight: '550' }}>{totalDocumentCount} Documents</span>
          </div>
          <IconButton aria-label="close" onClick={handleDrawerClose}>
            <CloseOutlined style={{ color: '#8992A1', fontSize: '12px' }} />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List sx={{ paddingTop: 0, overflowY: 'auto' }}>
          <div style={{ border: isCreateNewDoc ? '2px solid #0065FF' : 'none' }}>
            <ListItem button sx={{ borderWidth: '1px 1px 1px 2px', borderStyle: 'solid', borderColor: '#E1E2E2', justifyContent: 'center', padding: 0 }}
              disableRipple
            >
              <div
                draggable
                onDragStart={(event) => handleDragStart(event, -1)}
                onDragOver={(event) => handleDragOver(event, -1)}
                onDragLeave={(event) => handleDragLeave(event, -1)} // Add drag leave event handler
                onDrop={(event) => handleDrop(event, -1, {}, 3)}
                style={{
                  display: 'flex', alignItems: 'center', justifyContent: 'center',
                  flexDirection: 'column', margin: '0.25rem', padding: '0 2.75rem',
                  border: '1px dashed #D4D5D8',
                  opacity: isCreateNewDoc ? 0.5 : 1
                }}>
                {isCreateNewDoc &&
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', left: '45%' }}>
                    <img src={plusIcon} alt="add" />
                  </div>
                }
                <img src={addIcon} alt="add" style={{ padding: '0.5rem' }} />
                <div>Create New Document</div>
              </div>
            </ListItem>
          </div>


          {splitDocument.length > 0 && splitDocument.map((item, index) => (
            <ListItem disableRipple button sx={{ borderWidth: '1px 1px 1px 2px', borderStyle: 'solid', borderColor: '#E1E2E2', padding: 0 }}
            >
              <div
                style={{
                  display: 'flex', justifyContent: 'center',
                  flexDirection: 'column', margin: '0.25rem', padding: '0px 0.55rem',
                  border: '1px solid #D4D5D8',
                }}>
                <input
                  type="text"
                  className="form-control"
                  style={{ height: '30px' }}
                  name="documentName"
                  onChange={(e) => handleChange(e, index, true)}
                  placeholder="Document Name"
                  value={item.documentName}
                  required
                />
                <div style={{ padding: '0.6rem 0 0.6rem 0' }}>
                  <EditList
                    isNewDocument
                    handleClassificationMode={handleClassificationMode} index={index}
                    handleClassification={handleClassification}
                    splitDocument={splitDocument}
                    setSplitDocument={setSplitDocument}
                    width="12rem" />
                </div>
              </div>
            </ListItem>

          ))
          }

          {attachment && attachment.map((obj, index) => (

            (thumbnailCache.length === 0 || thumbnailCache[index]?.pages?.length > 0) && !obj.is_deleted && <ControlledTooltip
              text="New page(s) is appended at the end of the pdf file"
              open={obj?.showTooltip}
              position="right"
              sideDrawerTooltip
              handleCloseTooltip={() => handleCloseTooltip(obj)}
            >
              <div style={{ border: isHovered === index ? '2px solid #0065FF' : 'none' }}>
                <canvas ref={canvasRef} width={230} height={80} style={{ display: 'none' }} />
                <ListItem disableRipple button key={index} sx={{
                  borderWidth: '1px 1px 1px 2px', borderStyle: 'solid', borderColor: '#E1E2E2', display: 'flex', justifyContent: 'space-between',
                  backgroundColor: activeIndex === index ? '#E4E6EF' : 'transparent',
                  borderLeft: activeIndex === index && '2px solid #3C4C68',
                  '& .MuiTypography-root': {
                    fontWeight: activeIndex === index ? 500 : 'normal',
                  },
                  opacity: (isHovered === index || dragListItemIndex === index) ? 0.5 : 1,
                }}
                  draggable={true}
                  onClick={() => handleListItemClick(index)}
                  onDragStart={(event) => handleDragStart(event, index, 'list-item-drag')}
                  onDragOver={(event) => handleDragOver(event, index)}
                  // onDragLeave={(event) => handleDragLeave(event, index)} // Add drag leave event handler
                  onDrop={(event) => handleDrop(event, index, obj, 2)}
                  onDrag={handleDrag}
                  onDragEnd={handleDragEnd}
                >
                  {(isHovered === index) &&
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', left: '45%' }}>
                      <img src={plusIcon} alt="add" />
                    </div>
                  }
                  <div
                    onDragOver={(event) => handleDragOver(event, -3)} // rearrange
                    onDrop={(event) => handleDrop(event, index, obj, 4)}
                  >
                    {!obj.isNameEdit ? <ListItemText
                      className='h3Medium'
                      style={{
                        color: activeIndex === index ? '#15284B' : 'inherit',
                      }}
                      primary={obj.document_name}
                      onDoubleClick={() => handleClassificationMode(index, 'document_name')}
                    /> :

                      <input
                        type="text"
                        className="form-control"
                        style={{ height: '30px' }}
                        name="documentName"
                        onChange={(e) => handleChange(e, index)}
                        onKeyUp={(e) => handleChange(e, index)}
                        placeholder="Document Name"
                        value={obj.document_temp_name?.split('.')[0]}
                        required
                      />
                    }
                    {
                      !obj.isEdit ?
                        <span className='classification-chip' onDoubleClick={() => handleClassificationMode(index, 'classification')}>{obj?.output_json?.type}</span>
                        :
                        <span>
                          <EditList
                            handleClassificationMode={handleClassificationMode} index={index}
                            handleClassification={handleClassification}
                            width="10rem" />
                        </span>
                    }
                  </div>
                  {activeIndex === index && <div><img src={arrowRight} alt="arrow" /></div>}

                </ListItem>
              </div>
            </ControlledTooltip>

          ))}

          <ListItem onDragLeave={(event) => handleDragLeave(event, -2)} // Add drag leave event handler
          > </ListItem>
        </List>
      </Drawer>
      <Main
        open={isOpenDrawer} style={{ background: '#E4E6EF', padding: '0rem' }}>
        {loading ?
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '85vh' }}>
            <Loader sideDrawer />
          </div>
          :
          <>
            <div
              style={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem', padding: fullScreen ? '24px 24px 110px 24px' : '24px 24px 170px 24px', height: '100vh', overflowY: 'auto' }}>

              {thumbnailCache.length > 0 && thumbnailCache[activeIndex]?.pages.map((thumbnail, index) => (

                <div
                  style={{ borderLeft: thumbnailCache[activeIndex]?.pages.length > 1 && leftBorderIndex > -1 && leftBorderIndex === index && '2px solid #2D4081', paddingLeft: thumbnailCache[activeIndex]?.pages.length > 1 && leftBorderIndex > -1 && leftBorderIndex === index && '0.75rem' }}
                  key={index}
                  draggable={true}
                  onDragStart={(event) => handleDragStart(event, index, 'page_move')}
                  onDragOver={(event) => handleDragOver(event, -2, index)}
                  onDrop={(event) => handleDrop(event, index, thumbnailCache[activeIndex], 1)}
                  onDrag={handleDrag}
                  onMouseLeave={handleDraggableImagLeave}
                >
                  <div style={{ background: 'white', paddingBottom: '0.25rem' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '1rem', paddingBottom: '0.5rem' }}>
                      <div className='h5Regular' style={{ display: 'flex', alignItems: 'center', background: '#EAECF3', width: '1.5rem', height: '1.5rem', padding: '0.5rem' }}>
                        {showTempPage ? thumbnail?.page : index + 1}
                      </div>
                      <div style={{ display: 'flex', gap: '0.5rem', paddingTop: '0.5rem' }}>
                        <span style={{ cursor: 'pointer', display: 'flex', fontSize: '12px', color: 'dimgrey' }} onClick={() => handleExpandThumbnail(thumbnail?.src)}><ZoomInOutlined /></span>
                        <span style={{ display: 'flex' }}>
                          <input
                            type="checkbox"
                            checked={thumbnail?.checked}
                            onChange={() => handleToggleThumbnail(thumbnailCache[activeIndex].documentId, index)}
                          />
                        </span>
                      </div>
                    </div>
                    <div style={{ margin: '0 1rem 1rem', border: '1px solid #D4D5D8' }}>
                      <img src={thumbnail?.src} alt="page" style={{ width: "250px", height: "350px" }}
                        onDragStart={(event) => handleDragStart(event, index, 'page_move')}
                        draggable={false}
                      />
                    </div>
                  </div>
                </div>
              ))}

            </div>
            <div className='footer'>
              <div className='align-center' onClick={() => setSplitDocumentLength()}>
                <img src={splitIcon} alt="split" style={{ padding: '0 0.5rem' }} />
                <span className='h3Medium' style={{ color: 'white' }}>Split as separate Document</span>
              </div>
              <ButtonFilled text="Save"
                background='white' color='#2D4081'
                fontWeight='600'
                onClick={handleExistingAndNewDocumentChange}
                disabled={disableSaveBtn()} />
            </div>
          </>
        }   
      </Main>
      {dragItem && (
        <div
          id="custom-drag-image"
          style={{
            position: 'fixed',
            left: '50px',
            top: '50px',
            width: '230px',
            height: '80px',
            backgroundColor: 'white',
            border: '2px dashed #0065FF',
            zIndex: '9999',
            pointerEvents: 'none',
            padding: '8px 16px'
          }}
        >
          <div className='h2Medium' style={{ paddingBottom: '8px' }}>{dragItem.documentName}</div>
          <div className='classification-chip' style={{ width: 'fit-content' }}>{dragItem.classificationType}</div>
        </div>
      )}
      {feedbackCanvasImg &&
        <div id='custom-drag-image' style={{
          position: 'fixed',
          left: '0',
          top: '0',
          zIndex: '9999',
          pointerEvents: 'none',
          backgroundColor: 'white',
        }}>
          <img src={feedbackCanvasImgPath} alt="feedbackImg" width='150px'
            height='200px' />
        </div>}
      <Dialog
        onClose={() => setExpandThumbnail(false)}
        open={expandThumbnail}
        sx={{
          zIndex: '10000000',
          '& .MuiDialog-paper': {
            width: '50vw',
          }
        }}
      // BackdropProps={{
      //   style: {
      //     backgroundColor: 'rgba(0, 0, 0, 0.75)',
      //   },
      // }}
      >
        <img src={expandThumbnailSrc} alt="page" />
      </Dialog>
    </Box >
    </>
  );
};

export default SideDrawer;
