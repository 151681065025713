import { combineReducers } from 'redux';
import submissionsReducer from './submissionsReducer';
import timerReducer from './timerReducer';
import extractionReducer from './extractionReducer';
import comparisonReducer from './comparisonReducer';
import sliceReducer from './sliceReducer';
import auditReducer from './auditReducer';

const reducers = combineReducers({
    submissions: submissionsReducer,
    timer: timerReducer,
    extraction: extractionReducer,
    comparison: comparisonReducer,
    slice: sliceReducer,
    audit: auditReducer,
})

export default reducers;
