import { AuditActionTypes } from "../constants/auditActionTypes";

const initialState = {
  currentPage: 1,
};

const auditReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case AuditActionTypes.SET_CURRENT_PAGE:
      return { ...state, currentPage: payload };

    default:
      return state;
  }
};

export default auditReducer;
