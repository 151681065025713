// Library Import
import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Tab, Tabs } from '@mui/material';

// Files Import
import { axiosCancelToken } from '../../config/axios';
import useAxios from '../../hooks/useAxios';
import PacketTimer from '../../components/packet-timer/PacketTimer';
import { Loader } from '../../components';
import { removeExtractionDetails, removeSelectedDocumentId } from '../../redux/actions/extractionActions';
import { removeSelectedDocumentIds } from '../../redux/actions/comparisonActions';
import { removeSelectedSubmission, setSelectedSubmission } from '../../redux/actions/submissionActions';
import CustomizedTooltip from '../../components/atoms/Tooltip/CustomizedTooltip';
import { CanShowComparison, CanShowEnrichment, CanShowExtraction, CanShowValidation, screenTypes } from '../../services/constants/constants';

// Icons Import

// Styles Import
import './TabLayout.css';

function TabLayout() {
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { submissionId } = useParams();

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    if (newValue.toLowerCase() === screenTypes.EXTRACTION_VIEW.toLowerCase()) {
      const route = `/home/submissions/${submissionId}/${newValue}?docs=${selectedExtractionDocId ? selectedExtractionDocId : "''"}`;
      navigate(route);
    } else if (newValue.toLowerCase() === screenTypes.COMPARISON_VIEW.toLowerCase()) {
      const route = `/home/submissions/${submissionId}/${newValue}?docs=${selectedComparisonDocIds.length > 1 ? selectedComparisonDocIds.map((x) => x.document_id).join(",") : "''"}`;
      navigate(route);
    } else {
      const route = `/home/submissions/${submissionId}/${newValue}?docs=''`;
      navigate(route);
    }
  };


  const dispatch = useDispatch();
  const submission = useSelector(state => state.submissions.submission);
  const selectedExtractionDocId = useSelector(state => state.extraction.selectedDocumentId);
  const selectedComparisonDocIds = useSelector(state => state.comparison.selectedDocumentIds);
  const axiosPrivate = useAxios();

  const goHome = () => {
    navigate("/home/file-submissions");
  };

  const fetchSubmission = async (config) => {
    setLoading(true);
    axiosPrivate
      .get(`submissions/${submissionId}`, config)
      .then((response) => {
        dispatch(setSelectedSubmission(response.data));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {

    const source = axiosCancelToken.source();
    const config = { cancelToken: source.token };

    fetchSubmission(config);

    return () => {
      source.cancel();
      dispatch(removeSelectedSubmission());
      dispatch(removeSelectedDocumentId());
      dispatch(removeSelectedDocumentIds());
      dispatch(removeExtractionDetails());
    };
  }, []);


  useEffect(() => {
    const path = location.pathname.split('/');
    setSelectedTab(path[path.length - 1]);
  }, [location]);

  return (
    <React.Fragment>
      <Loader loading={loading} />
      <div className='primary-header'>
        <div>
          <IconButton onClick={goHome} disableRipple>
            <img src={"assets/images/left-arrow.svg"} alt="leftArrow" />
          </IconButton>
          <CustomizedTooltip text={submission.submission_description}>
            <span
              className="h3Medium truncate-primary-header-description ml-1"
            >
              {submission.submission_description}
            </span>
          </CustomizedTooltip>
        </div>
        <div>
          <PacketTimer setLoader={setLoading} />
        </div>
      </div>
      <div className='tabs-container'>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          variant="fullWidth"
          sx={{
            backgroundColor: 'white',
            color: '#2D4081',
            // borderBottom: '1px solid #0065FF',
            '& .Mui-selected': {
              backgroundColor: '#EAECF3'
            }
          }}
        >
          <Tab
            label="Extraction"
            value="extraction"
            className="primary-tab-style"
            disabled={!CanShowExtraction}
          />
          <Tab
            label="Comparison"
            value="comparison"
            className="primary-tab-style"
            disabled={!CanShowComparison}
          />
          <Tab
            label="Enrichment"
            value="enrichment"
            className="primary-tab-style"
            disabled={!CanShowEnrichment}
          />
          <Tab
            label="Validation"
            value="validation"
            className="primary-tab-style"
            disabled={!CanShowValidation}
          />
        </Tabs>
      </div>

      <section>
        <div id='tab-flow-outlet'>
          <Outlet />
        </div>
      </section>
    </React.Fragment>
  );
}

export default TabLayout;
